package admin.services.api

import admin.models.system.HardDriveInfo
import admin.models.system.MemoryInfo
import admin.serialization.adminFormat
import admin.services.SystemApiService
import admin.services.api.models.UpdateRequest
import endpoints.Endpoints.ACCESS
import endpoints.Endpoints.ACCOUNTS
import endpoints.Endpoints.ADMIN
import endpoints.Endpoints.API
import endpoints.Endpoints.DEVICES
import endpoints.Endpoints.HARD_DRIVE
import endpoints.Endpoints.MEMORY
import endpoints.Endpoints.SESSION
import endpoints.Endpoints.SYSTEM
import endpoints.Endpoints.UPDATES
import kotlinx.browser.window
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import logs.debugLog
import models.access.Access
import models.account.Account
import models.api.BadRequest
import models.api.Forbidden
import models.api.InternalServerError
import models.api.MethodNotAllowed
import models.api.NotFound
import models.api.OtherApiError
import models.api.OtherError
import models.api.Unauthorized
import models.api.session.Credential
import models.devices.Device
import models.update.Update
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import org.w3c.fetch.Headers
import org.w3c.fetch.Request
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.files.FileReader
import org.w3c.xhr.FormData
import tools.coAsync

class ApiServiceImpl : ApiService {

    override val session = object : SessionApiService {

        private val sessionUrl = "http://${window.location.host}$API$ADMIN$SESSION"

        override fun newSessionAsync(credential: Credential): Deferred<String> = coAsync {
            debugLog(TAG, "$POST: session (login: ${credential.login})")
            val map = mapOf("login" to credential.login, "password" to credential.password)
            val request = Request(sessionUrl, RequestInit(POST, map.asHeaders()))

            val token = call(request).headers.get("token")
            return@coAsync token ?: throw Exception("missing auth token")
        }

        override fun removeSessionAsync(authToken: String): Deferred<Unit> = coAsync {
            debugLog(TAG, "$DELETE: session (logout)")
            val map = mapOf("Authorization" to authToken)
            val request = Request(sessionUrl, RequestInit(DELETE, map.asHeaders()))

            call(request)
        }

        override fun isSessionExistsAsync(authToken: String): Deferred<Unit> = coAsync {
            debugLog(TAG, "$HEAD: session (is exists)")
            val map = mapOf("Authorization" to authToken)
            val request = Request(sessionUrl, RequestInit(HEAD, map.asHeaders()))

            call(request)
        }
    }

    override val updates = object : UpdatesApiService {

        private val updatesUrl = "http://${window.location.host}$API$ADMIN$UPDATES"

        override fun listAsync(authToken: String, device: String, model: String): Deferred<List<Update>> = coAsync {
            debugLog(TAG, "$GET: get updates for $device $model")
            val map = mapOf(
                "Authorization" to authToken,
                "Content-Type" to "application/json",
                "device" to device,
                "model" to model,
            )
            val request = Request(updatesUrl, RequestInit(GET, map.asHeaders()))

            val response = call(request)
            return@coAsync adminFormat.decodeFromString<List<Update>>(response.text().await())
        }

        override fun newUpdateAsync(authToken: String, updateRequest: UpdateRequest): Deferred<Unit> = coAsync {
            debugLog(TAG, "$POST: new update for ${updateRequest.device} ${updateRequest.model}")

            val job = Job()
            var checksum = ""

            FileReader().apply {
                readAsArrayBuffer(updateRequest.file)
                onloadend = {
                    if (it.target.asDynamic().readyState == FileReader.DONE) {
                        val arrayBuffer = it.target.asDynamic().result as ArrayBuffer
                        val array = Uint8Array(arrayBuffer)
                        val fileByteArray = ByteArray(array.length)
                        (0 until array.length).forEach { i -> fileByteArray[i] = array[i] }
                        checksum = fileByteArray.contentHashCode().toString()
                        job.complete()
                    }
                }
            }

            job.join()

            val map = mapOf(
                "Authorization" to authToken,
                "version" to updateRequest.version,
                "device" to updateRequest.device,
                "model" to updateRequest.model,
                "measurementVersion" to updateRequest.measurementVersion.toString(),
                "processVersion" to updateRequest.processVersion.toString(),
                "settingsVersion" to updateRequest.settingsVersion.toString(),
                "changelog" to updateRequest.changelog.joinToString(";"),
                "checksum" to checksum,
            )

            val request = Request(
                input = updatesUrl,
                init = RequestInit(
                    method = POST,
                    headers = map.asHeaders(),
                    body = FormData().apply { append("file", updateRequest.file) },
                ),
            )

            call(request)
        }

        override fun removeUpdateAsync(authToken: String, uuid: String): Deferred<Unit> = coAsync {
            debugLog(TAG, "$DELETE: update for $uuid")
            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json", "uuid" to uuid)
            val request = Request(updatesUrl, RequestInit(DELETE, map.asHeaders()))

            call(request)
        }
    }

    override val system = object : SystemApiService {

        private val systemUrl = "http://${window.location.host}$API$ADMIN$SYSTEM"

        override fun getMemoryInfoAsync(authToken: String): Deferred<MemoryInfo> = coAsync {
            debugLog(TAG, "$GET: get system memory info")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val request = Request("$systemUrl$MEMORY", RequestInit(GET, map.asHeaders()))

            val response = call(request)
            return@coAsync adminFormat.decodeFromString<MemoryInfo>(response.text().await())
        }

        override fun getHardDriveInfoAsync(authToken: String): Deferred<HardDriveInfo> = coAsync {
            debugLog(TAG, "$GET: get system hard drive info")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val request = Request("$systemUrl$HARD_DRIVE", RequestInit(GET, map.asHeaders()))

            val response = call(request)
            return@coAsync adminFormat.decodeFromString<HardDriveInfo>(response.text().await())
        }
    }

    override val accounts = object : AccountsApiService {

        private val accountsUrl = "http://${window.location.host}$API$ADMIN$ACCOUNTS"

        override fun listAsync(authToken: String, query: String): Deferred<List<Account>> = coAsync {
            debugLog(TAG, "$GET: get list accounts for query: $query")

            val map = mapOf(
                "Authorization" to authToken,
                "Content-Type" to "application/json",
                "query" to query,
            )
            val request = Request(accountsUrl, RequestInit(GET, map.asHeaders()))

            val response = call(request)
            return@coAsync adminFormat.decodeFromString<List<Account>>(response.text().await())
        }

        override fun addAsync(authToken: String, login: String, password: String, email: String): Deferred<Unit> =
            coAsync {
                debugLog(TAG, "$POST: create account for $email")

                val map = mapOf(
                    "Authorization" to authToken,
                    "Content-Type" to "application/json",
                    "login" to login,
                    "password" to password,
                    "email" to email,
                )

                val request = Request(accountsUrl, RequestInit(POST, map.asHeaders()))

                call(request)
            }

        override fun updateAsync(authToken: String, account: Account): Deferred<Unit> = coAsync {
            debugLog(TAG, "$PUT: update account: ${account.uuid}")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val body = adminFormat.encodeToString(account)
            val request = Request("$accountsUrl/${account.uuid}", RequestInit(PUT, map.asHeaders(), body))

            call(request)
        }

        override fun deleteAsync(authToken: String, accountUuid: String): Deferred<Unit> = coAsync {
            debugLog(TAG, "$DELETE: delete account: $accountUuid")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val request = Request("$accountsUrl/$accountUuid", RequestInit(DELETE, map.asHeaders()))

            call(request)
        }
    }

    override val access = object : AccessApiService {

        private val accessUrl = "http://${window.location.host}$API$ADMIN$ACCESS"

        override fun getAccessesAsync(authToken: String, accountUuid: String): Deferred<List<Access>> = coAsync {
            debugLog(TAG, "$GET: get accesses for account: $accountUuid")

            val map = mapOf(
                "Authorization" to authToken,
                "Content-Type" to "application/json",
                "uuid" to accountUuid,
            )
            val request = Request(accessUrl, RequestInit(GET, map.asHeaders()))

            val response = call(request)
            return@coAsync adminFormat.decodeFromString<List<Access>>(response.text().await())
        }

        override fun deleteAccessAsync(authToken: String, accessUuid: String): Deferred<Unit> = coAsync {
            debugLog(TAG, "$DELETE: delete access: $accessUuid")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val request = Request("$accessUrl/$accessUuid", RequestInit(DELETE, map.asHeaders()))

            call(request)
        }
    }
    override val device = object : DeviceApiService {

        private val devicesUrl = "http://${window.location.host}$API$ADMIN$DEVICES"

        override fun listAsync(authToken: String, serialNumber: String): Deferred<List<Device>> = coAsync {
            debugLog(TAG, "$GET: get list devices for query: $serialNumber")

            val map = buildMap {
                put("Authorization", authToken)
                put("Content-Type", "application/json")
                if (serialNumber.isNotBlank()) put("serialNumber", serialNumber)
            }

            val request = Request(devicesUrl, RequestInit(GET, map.asHeaders()))

            return@coAsync call(request)
                .let { response -> adminFormat.decodeFromString<List<Device>>(response.text().await()) }
        }

        override fun addAsync(authToken: String, device: Device): Deferred<Unit> = coAsync {
            debugLog(TAG, "$POST: create device for ${device.serialNumber}")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val body = adminFormat.encodeToString(device)

            val request = Request(devicesUrl, RequestInit(POST, map.asHeaders(), body))

            call(request)
        }

        override fun updateAsync(authToken: String, device: Device): Deferred<Unit> = coAsync {
            debugLog(TAG, "$PUT: update device: ${device.serialNumber}")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val body = adminFormat.encodeToString(device)
            val request = Request("$devicesUrl/${device.serialNumber}", RequestInit(PUT, map.asHeaders(), body))

            call(request)
        }

        override fun deleteAsync(authToken: String, serialNumber: String): Deferred<Unit> = coAsync {
            debugLog(TAG, "$DELETE: delete device: $serialNumber")

            val map = mapOf("Authorization" to authToken, "Content-Type" to "application/json")
            val request = Request("$devicesUrl/$serialNumber", RequestInit(DELETE, map.asHeaders()))

            call(request)
        }
    }

    suspend fun call(request: Request): Response {
        val response = try {
            window.fetch(request).await()
        } catch (throwable: Throwable) {
            throw OtherError(throwable.message)
        }

        debugLog(TAG, "Response: [${response.status}] ${response.statusText}")
        return when (response.status) {
            in 0..299 -> response
            400.toShort() -> throw BadRequest()
            401.toShort() -> throw Unauthorized()
            403.toShort() -> throw Forbidden()
            404.toShort() -> throw NotFound()
            405.toShort() -> throw MethodNotAllowed()
            500.toShort() -> throw InternalServerError()
            else -> throw OtherApiError(response.status)
        }
    }

    fun Map<String, String>.asHeaders() = Headers().apply { forEach { append(it.key, it.value) } }

    companion object {
        const val TAG = "[API]"

        const val GET = "GET"
        const val POST = "POST"
        const val PUT = "PUT"
        const val HEAD = "HEAD"
        const val DELETE = "DELETE"
    }
}
