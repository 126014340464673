package admin.services.auth

import admin.services.api.SessionApiService
import admin.services.auth.models.AuthEvents
import admin.services.cookie.CookieService
import admin.services.cookie.CookieService.Companion.AUTH_TOKEN
import extensions.getValue
import models.api.session.Credential
import tools.bufferSharedFlow

class AuthServiceImpl(
    private val api: SessionApiService,
    private val cookieService: CookieService,
) : AuthService {

    override val tokenFlow = bufferSharedFlow<String>()

    override var events: AuthEvents = AuthEvents({}, {}, {})

    override suspend fun login(serialNumber: String, password: String) {
        val credential = Credential(serialNumber, password)
        val authToken = api.newSessionAsync(credential).await()

        updateSessions(authToken)
        events.onLogin(authToken)
    }

    override suspend fun logout() {
        val authToken = tokenFlow.getValue() ?: throw Exception("No token in tokenFlow")
        api.removeSessionAsync(authToken).await()

        updateSessions("")
        events.onLogout()
    }

    override suspend fun autoLogin() {
        val authToken = cookieService[AUTH_TOKEN] ?: throw Exception("No auth token in cookie.")
        api.isSessionExistsAsync(authToken).await()

        tokenFlow.tryEmit(authToken)
        events.onLogin(authToken)
    }

    private fun updateSessions(authToken: String) {
        cookieService[AUTH_TOKEN] = authToken
        tokenFlow.tryEmit(authToken)
    }

    companion object {
        const val TAG = "[AUTH]"
    }
}
