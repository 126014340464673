package admin.models.system

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import websocket.WsAdminData
import websocket.WsDataCodes.SERVER_STATE_NETWORK_V1

@Model(ADMIN)
@Serializable
@SerialName(SERVER_STATE_NETWORK_V1)
data class Network(

    /** download in bytes */
    @SerialName("d")
    val download: Long?,

    /** upload in bytes */
    @SerialName("u")
    val upload: Long?,

) : WsAdminData
