package admin.ui.users.manager.windows.edit

import admin.services.api.AccessApiService
import admin.services.api.AccountsApiService
import admin.services.auth.AuthService
import extensions.getValue
import models.access.Access
import models.account.Account
import navigator.observable.vm.ObservableList
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel

class UserEditWindowViewModel(
    private val account: Account,
    private val authService: AuthService,
    private val accountsApiService: AccountsApiService,
    private val accessApiService: AccessApiService,
) : ViewModel() {

    val login = ObservableValue(account.login)
    val password = ObservableValue(account.password)
    val email = ObservableValue(account.email)

    val accesses = ObservableList<Access>()

    suspend fun refresh() {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at save init edit account window")
            return
        }

        accesses.empty()
        accesses += accessApiService.getAccessesAsync(authToken, account.uuid).await()
    }

    suspend fun save(): Boolean {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at save edit account")
            return false
        }

        val account = Account(
            uuid = account.uuid,
            login = login.value ?: return false,
            password = password.value ?: return false,
            email = email.value ?: return false,
            type = account.type,
        )

        accountsApiService.updateAsync(authToken = authToken, account = account).await()

        return true
    }

    suspend fun deleteAccess(uuid: String): Boolean {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at save edit account")
            return false
        }

        accessApiService.deleteAccessAsync(authToken, uuid).await()
        return true
    }

    suspend fun deleteAccount(): Boolean {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at save edit account")
            return false
        }

        accountsApiService.deleteAsync(authToken, account.uuid).await()
        return true
    }
}
