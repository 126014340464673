package models.devices.controller.settings.client

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
data class ClientAdvanceSettingsV1(

    /** Polish names: Skraplacz(condenser), Głowica(head) */
    @SerialName("h")
    val headThermometerPosition: Int?,

    /** Polish names: Odstojnik(clarifier), Bufor(buffer) */
    @SerialName("b")
    val bufferThermometerPosition: Int?,

    /** Polish names: Beczka(keg), Zbiornik(tank) */
    @SerialName("t")
    val tankThermometerPosition: Int?,
)
