package models.devices.thermometers.measurements

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE
import rules.ModelDestination.THERMOMETERS

@Model(CORE, CLIENT, ADMIN, THERMOMETERS)
@Serializable
data class TemperaturesV1(

    @SerialName("t1")
    val temperature1: Float,

    @SerialName("t2")
    val temperature2: Float,

    @SerialName("t3")
    val temperature3: Float,

    @SerialName("t4")
    val temperature4: Float,

    @SerialName("t5")
    val temperature5: Float,
)
