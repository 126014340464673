package logs

import logs.Logger.loggerUi

/**
 * Simply logger
 * It is temporary solution. Use "browserDevelopmentWebpack" and "browserProductionWebpack" to exclude unnecessary code.
 * https://kotlinlang.org/docs/reference/js-project-setup.html
 *
 * You can block logs by using enable flags or by set the TAG for null.
 */

// TODO Create one logger both, to JS and JVM
object Logger {
    var isInfoEnable = true
    var isDebugEnable = true
    var isErrorEnable = true
    var isWarningEnable = true

    var loggerUi: LoggerUi? = null
}

fun infoLog(tag: String?, text: String) {
    if (Logger.isInfoEnable && tag != null) console.log("$tag $text")
    loggerUi?.log("$tag $text")
}

fun debugLog(tag: String?, text: String) {
    if (Logger.isDebugEnable && tag != null) console.log("$tag $text")
    loggerUi?.log("$tag $text")
}

fun errorLog(tag: String?, text: String) {
    if (Logger.isErrorEnable && tag != null) console.error("$tag $text")
    loggerUi?.error("$tag $text")
}

fun warnLog(tag: String?, text: String) {
    if (Logger.isWarningEnable && tag != null) console.warn("$tag $text")
    loggerUi?.warn("$tag $text")
}
