package models.account

import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN)
@Serializable
data class Account(
    val uuid: String,
    val login: String,
    val type: Byte,
    val password: String,
    val email: String,
)
