package extensions

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.firstOrNull
import tools.co

suspend fun <T> SharedFlow<T>.getValue(): T? = when {
    replayCache.isEmpty() -> null
    else -> firstOrNull()
}

fun <E> Flow<E>.collectCo(
    scope: CoroutineScope = MainScope(),
    action: suspend (E) -> Unit,
): Job {
    return co(scope) { collect { action(it) } }
}

fun <E> MutableSharedFlow<E>.emitCo(
    value: E,
    scope: CoroutineScope = MainScope(),
): Job {
    return co(scope) { tryEmit(value) }
}
