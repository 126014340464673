package admin.ui.users.manager.windows.add

import admin.services.api.AccountsApiService
import admin.services.auth.AuthService
import extensions.getValue
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel

class UserAddWindowViewModel(
    private val authService: AuthService,
    private val accountsApiService: AccountsApiService,
) : ViewModel() {

    val login = ObservableValue<String>()
    val password = ObservableValue<String>()
    val email = ObservableValue<String>()

    suspend fun save(): Boolean {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at save new account")
            return false
        }

        val login = login.value ?: return false
        val password = password.value ?: return false
        val email = email.value ?: return false

        accountsApiService.addAsync(
            authToken = authToken,
            login = login,
            password = password,
            email = email,
        ).await()

        return true
    }
}
