package exntensions

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.MainScope
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import tools.co

inline fun HTMLElement.onClick(noinline event: (HTMLElement, Event) -> Unit) {
    return addEventListener("click", { event(this, it) })
}

inline fun HTMLElement.onClickCo(
    scope: CoroutineScope = MainScope(),
    noinline event: suspend (HTMLElement, Event) -> Unit,
) {
    return addEventListener("click", { co(scope) { event(this, it) } })
}

inline fun HTMLElement.gone() = apply { style.display = "none" }

inline fun HTMLElement.show() = apply { style.display = "block" }

inline fun HTMLElement.clearDisplay() = apply { style.removeProperty("display") }

inline fun HTMLElement.boldFont() = apply { style.fontWeight = "bold" }

inline fun HTMLElement.normalFont() = apply { style.fontWeight = "normal" }

inline fun HTMLElement.backgroundColor(color: String) = apply { style.backgroundColor = color }
