package navigator.observable.view

import exntensions.onClick
import kotlinx.browser.document
import logs.errorLog
import navigator.observable.vm.ObservableValue
import org.w3c.dom.HTMLElement
import tools.co

class ObservableButton<T : HTMLElement>(private val elementId: String) {

    private var element: T = document.getElementById(elementId).asDynamic() as? T ?: let {
        errorLog(TAG, "missing $elementId")
        throw Throwable("missing element")
    }

    fun onInitialize(action: (T) -> Unit = {}): ObservableButton<T> {
        action(element)
        return this
    }

    fun onClick(action: (T) -> Unit = {}): ObservableButton<T> {
        element.onClick { _, _ -> action(element) }
        return this
    }

    fun onClickCo(action: suspend (T) -> Unit = {}): ObservableButton<T> {
        element.onClick { _, _ -> co { action(element) } }
        return this
    }

    fun bindEnable(vararg isEnable: ObservableValue<Boolean>, action: (Boolean, T) -> Unit): ObservableButton<T> {
        isEnable.forEach { observable ->
            action(isEnable.all { it.value == true }, element) // reload view
            observable.onChanged {
                action(isEnable.all { it.value == true }, element)
            }
        }

        return this
    }

    fun bindEnables(
        vararg conditions: ObservableValue<*>,
        action: (List<Any>, T) -> Unit,
    ): ObservableButton<T> {
        conditions
            .mapNotNull { it.value }
            .takeIf { it.size == conditions.size }
            ?.let { values -> action(values, element) }

        conditions.forEach { condition ->
            condition.onChanged {
                conditions
                    .mapNotNull { it.value }
                    .takeIf { it.size == conditions.size }
                    ?.let { values -> action(values, element) }
            }
        }

        return this
    }

    fun bindEnables(
        vararg conditions: ObservableValue<Any>,
        action: (List<Any>, T) -> Unit,
    ): ObservableButton<T> {
        conditions
            .mapNotNull { it.value }
            .takeIf { it.size == conditions.size }
            ?.let { values -> action(values, element) }

        conditions.forEach { condition ->
            condition.onChanged {
                conditions
                    .mapNotNull { it.value }
                    .takeIf { it.size == conditions.size }
                    ?.let { values -> action(values, element) }
            }
        }

        return this
    }

    fun <OV> bindObservable(observable: ObservableValue<OV>, action: (OV?, T) -> Unit): ObservableButton<T> {
        observable.value?.let { action(it, element) } // reload view
        observable.onChanged { action(it, element) }
        return this
    }

    fun <OV> bindNullableObservable(observable: ObservableValue<OV>, action: (OV?, T) -> Unit): ObservableButton<T> {
        action(observable.value, element) // reload view
        observable.onChanged { action(it, element) }
        return this
    }

    companion object {
        private const val TAG = "[OBSERVABLE]"
    }
}
