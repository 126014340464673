package admin.ui.devices.manager.edit

import admin.services.api.DeviceApiService
import admin.services.auth.AuthService
import extensions.getValue
import models.devices.Device
import models.devices.DeviceType
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel

class DeviceEditWindowViewModel(
    private val device: Device,
    private val authService: AuthService,
    private val deviceApiService: DeviceApiService,
) : ViewModel() {

    val typeId = ObservableValue(device.type.id)
    val model = ObservableValue(device.model)
    val password = ObservableValue(device.password)
    val softwareVersion = ObservableValue(device.softwareVersion)
    val hardwareVersion = ObservableValue(device.hardwareVersion)

    suspend fun save(): Boolean {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at save edit account")
            return false
        }

        val device = Device(
            serialNumber = device.serialNumber,
            type = typeId.value?.let { DeviceType.create(it) } ?: return false,
            model = model.value ?: return false,
            password = password.value ?: return false,
            softwareVersion = softwareVersion.value ?: return false,
            hardwareVersion = hardwareVersion.value ?: return false,
        )

        deviceApiService.updateAsync(authToken = authToken, device = device).await()

        return true
    }

    suspend fun deleteDevice(): Boolean {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at delete edit device")
            return false
        }

        deviceApiService.deleteAsync(authToken, device.serialNumber).await()
        return true
    }
}
