package admin.ui.devices.manager.add

import admin.services.api.DeviceApiService
import admin.services.auth.AuthService
import kotlinx.browser.window
import kotlinx.coroutines.await
import logs.infoLog
import navigator.html.DynamicHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableInput
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import tools.co

class DeviceAddWindowView(
    private val authService: AuthService,
    private val deviceApiService: DeviceApiService,
    private val onSaveClicked: () -> Unit,
    private val onCancelClicked: () -> Unit,
) : DynamicHtml() {

    private lateinit var view: String
    private lateinit var viewModel: DeviceAddWindowViewModel

    suspend fun createView(): String {
        view = window
            .fetch("${window.location.origin}$WINDOW_HTML_PATH")
            .then { it.text() }.await().toString()

        viewModel = DeviceAddWindowViewModel(authService, deviceApiService)

        return view
    }

    fun load() {
        ObservableInput<HTMLInputElement>("device-manager-add-serial-number-name")
            .bindObservable(viewModel.serialNumber) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.serialNumber.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-type-name")
            .bindObservable(viewModel.type) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.type.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-model-name")
            .bindObservable(viewModel.model) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.model.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-password-name")
            .bindObservable(viewModel.password) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.password.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-sv-name")
            .bindObservable(viewModel.softwareVersion) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.softwareVersion.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-hv-name")
            .bindObservable(viewModel.hardwareVersion) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.hardwareVersion.value = value }

        ObservableButton<HTMLDivElement>("device-manager-add-save-button")
            .onClick {
                infoLog(TAG, "Clicked Save")
                co { if (viewModel.save()) onSaveClicked() }
            }

        ObservableButton<HTMLDivElement>("device-manager-add-cancel-button")
            .onClick {
                infoLog(TAG, "Clicked Cancel")
                onCancelClicked()
            }
    }

    fun destroy() {
        viewModel.destroy()
    }

    companion object {
        private const val TAG = "[NEW_ACCOUNT_WINDOW]"
        private const val WINDOW_HTML_PATH = "/adminBody/devices/manager/manager_add_window.html"
    }
}
