package models.devices.controller.settings.auto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsControllerData
import websocket.WsDataCodes.CONTROLLER_AUTO_SETTINGS_V1

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
@SerialName(CONTROLLER_AUTO_SETTINGS_V1)
data class AutoSettingsV1(

    @SerialName("n")
    val name: String,

) : WsControllerData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return "AutoSettingsV1(n=$name)"
    }
}
