package admin.ui.devices.manager.add

import admin.services.api.DeviceApiService
import admin.services.auth.AuthService
import extensions.getValue
import models.devices.Device
import models.devices.DeviceType
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel

class DeviceAddWindowViewModel(
    private val authService: AuthService,
    private val deviceApiService: DeviceApiService,
) : ViewModel() {

    val serialNumber = ObservableValue<String>()
    val type = ObservableValue("0")
    val model = ObservableValue<String>()
    val password = ObservableValue("auto")
    val softwareVersion = ObservableValue<String>()
    val hardwareVersion = ObservableValue<String>()

    suspend fun save(): Boolean {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at save new device")
            return false
        }

        val device = Device(
            serialNumber = serialNumber.value ?: return false,
            type = type.value?.toByte()?.let { DeviceType.create(it) } ?: return false,
            model = model.value ?: return false,
            password = password.value ?: return false,
            softwareVersion = softwareVersion.value ?: return false,
            hardwareVersion = hardwareVersion.value ?: return false,
        )

        deviceApiService.addAsync(authToken = authToken, device = device).await()

        return true
    }
}
