package admin.services.websocket

import admin.models.websocket.Ws2Admin
import admin.models.websocket.Ws2Server
import admin.models.websocket.WsAdminAction.CLOSE_CONNECTION
import admin.models.websocket.WsAdminAction.OPEN_CONNECTION
import admin.serialization.adminFormat
import endpoints.Endpoints.ADMIN
import endpoints.Endpoints.API
import endpoints.Endpoints.WEBSOCKET
import kotlinx.browser.window
import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import org.w3c.dom.CloseEvent
import org.w3c.dom.WebSocket
import tools.bufferSharedFlow
import tools.cancelActive
import tools.co
import tools.completeActive

class WebSocketServiceImpl : WebSocketService {

    private var webSocket: WebSocket? = null
    private lateinit var token: String

    override val incoming: MutableSharedFlow<Ws2Admin> = bufferSharedFlow(buffer = 100)

    override suspend fun start(authToken: String) {
        console.log("$TAG Start()")

        val job: CompletableJob = Job()
        token = authToken
        webSocket = WebSocket(url)

        webSocket?.onclose = {
            it as CloseEvent
            co {
                console.log("$TAG Closing connection... ${it.code} - ${it.reason}")
                job.cancelActive()
            }
        }

        webSocket?.onopen = {
            co {
                console.log("$TAG Opening connection...")
                delay(600) // wait for server sync operation
                job.completeActive()
            }
        }

        webSocket?.onmessage = { message ->
            val ws2Admin = adminFormat.decodeFromString<Ws2Admin>(message.data as String)
            console.log("$TAG OnMessage: ${ws2Admin.action}")
            incoming.tryEmit(ws2Admin)
        }

        job.join()

        openConnection()
    }

    override suspend fun restart() {
        try {
            webSocket?.close()
        } catch (t: Throwable) {
            console.warn("$TAG Cannot close() websocket before start(): $t")
        }

        start(token)
    }

    override suspend fun stop() {
        console.log("$TAG Stop()")
        closeConnection()
        webSocket?.close()
    }

    override suspend fun send(ws2Server: Ws2Server) {
        val json = adminFormat.encodeToString(ws2Server)
        console.log("$TAG Send ${ws2Server.action}")
        webSocket?.send(json)
    }

    private suspend fun openConnection() {
        send(Ws2Server(OPEN_CONNECTION, token))
    }

    private suspend fun closeConnection() {
        send(Ws2Server(CLOSE_CONNECTION, token))
    }

    companion object {
        private val url = "ws://${window.location.host}$API$ADMIN$WEBSOCKET"
        private const val TAG = "[WS]"
    }
}
