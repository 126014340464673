package navigator.view

import navigator.Navigator
import navigator.html.StaticHtml

interface ViewNode : View {

    val urlBitIndex: Int

    val navigator: Navigator

    val contentViewId: String

    var htmlView: StaticHtml?

    /** Connecting UI and logic. */
    override suspend fun onLoad(addressUrl: List<String>): LoadResult

    /** Refreshing UI and logic. Used in case where user navigate to some smaller urlBitIndex number. */
    override suspend fun onReload(addressUrl: List<String>)

    /** Destroy UI and logic. */
    override suspend fun onDestroy()

    /** Used only by navigator. */
    suspend fun navigateTo(addressUrl: List<String>)

    /** Make operation on html content and history of address url... */
    suspend fun buildView(addressUrl: List<String>, urlBit: String)

    suspend fun run(addressUrl: List<String>, urlBit: String) {
        if (htmlView?.getUrlBit() == null) {
            buildView(addressUrl, urlBit)
            (htmlView as View).onLoad(addressUrl)
            (htmlView as? ViewNode)?.navigateTo(addressUrl)
        } else if (htmlView!!.getUrlBit() == urlBit) { // is already open
            if (urlBit == addressUrl.getOrNull(urlBitIndex)) {
                (htmlView as View).onReload(addressUrl)
            }
            (htmlView as? ViewNode)?.navigateTo(addressUrl)
        } else {
            (htmlView as View).onDestroy()
            buildView(addressUrl, urlBit)
            (htmlView as View).onLoad(addressUrl)
            (htmlView as? ViewNode)?.navigateTo(addressUrl)
        }
    }
}
