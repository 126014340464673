package models.devices.controller.event

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import models.devices.controller.event.alert.OffTempAlertEventV1
import models.devices.controller.event.process.ProcessEventV1
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsControllerData
import websocket.WsDataCodes.CONTROLLER_EVENT_V1

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
@SerialName(CONTROLLER_EVENT_V1)
data class EventV1(

    @SerialName("d")
    val data: EventData,
) : WsControllerData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return when (data) {
            is OffTempAlertEventV1 -> data.getLogText()
            is ProcessEventV1 -> data.getLogText()
            else -> "Event not recognized: $data"
        }
    }
}
