package models.devices.controller.event

import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
abstract class EventData {

    companion object {
        const val PROCESS_V1 = "0"
        const val OFF_TEMP_ALERT_V1 = "1"
        const val ACCESS_2_CONTROLLER_V1 = "2"
        const val ACCESS_2_SERVER_V1 = "3"
        const val ACCESS_2_CLIENT_V1 = "4"
    }
}
