package models.devices.thermometers.settings.client

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import models.devices.controller.settings.client.ClientAdvanceSettingsV1
import models.devices.controller.settings.client.ClientThermometerV1
import rules.Model
import rules.ModelDestination
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsDataCodes

@Model(ModelDestination.CORE, ModelDestination.CLIENT, ModelDestination.ADMIN)
@Serializable
@SerialName(WsDataCodes.THERMOMETERS_CLIENT_SETTINGS_V1)
data class ClientSettingsV1(

    @SerialName("t1")
    val thermometer1: ClientThermometerV1,

    @SerialName("t2")
    val thermometer2: ClientThermometerV1,

    @SerialName("t3")
    val thermometer3: ClientThermometerV1,

    @SerialName("t4")
    val thermometer4: ClientThermometerV1,

    @SerialName("t5")
    val thermometer5: ClientThermometerV1,

    @SerialName("a")
    val advance: ClientAdvanceSettingsV1,
) : WsClientData, WsAdminData
