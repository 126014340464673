package navigator.html

import kotlinx.browser.window
import kotlinx.coroutines.await

abstract class StaticHtml(
    val constUrlBit: String,
    private val resourceUrl: String,
) : DynamicHtml() {

    var dynamicUrlBit: String? = null
    var content: String? = null

    suspend fun getView(): String = content ?: window
        .fetch("${window.location.origin}$resourceUrl")
        .then { it.text() }.await().toString() // response
        .let { html -> fetchAllComponents(html) }
        .also { html -> content = html }

    fun getUrlBit() = dynamicUrlBit ?: constUrlBit

    /** component must be applied in single line at html. */
    private suspend fun fetchAllComponents(html: String): String {
        return html.lines()
            .map { line ->
                if (line.contains("component=")) {
                    val componentUrl = line.split("component=")[1].split("\"")[1]
                    val element = line.replace(" component=\"$componentUrl\"", "")
                    val path = componentUrl.split(".")[0].split("-").joinToString("")

                    val component = window
                        .fetch("${window.location.origin}/components/$path/$componentUrl")
                        .then { it.text() }.await().toString()

                    element.replaceFirst(">", ">$component")
                } else {
                    line
                }
            }
            .joinToString("\n")
    }
}
