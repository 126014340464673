package navigator.observable.vm

/** You must remember to clean manually listeners or use ViewModel class. */
class ObservableList<T>(defaultValue: List<T> = listOf()) : Observable, MutableList<T> {

    private val list = mutableListOf(*defaultValue.toTypedArray())
    private val listeners = mutableListOf<(List<T>) -> Unit>()

    override val size: Int
        get() = list.size

    fun onChanged(action: (List<T>) -> Unit) = Unit.also { listeners.add(action) }

    private fun notifyAll() = listeners.forEach { it(list) }

    override fun contains(element: T): Boolean = list.contains(element)

    override fun containsAll(elements: Collection<T>): Boolean = list.containsAll(elements)

    override fun get(index: Int): T = list[index]

    override fun indexOf(element: T): Int = list.indexOf(element)

    override fun isEmpty(): Boolean = list.isEmpty()

    override fun lastIndexOf(element: T): Int = list.lastIndexOf(element)

    override fun iterator(): MutableIterator<T> = list.iterator()

    override fun listIterator(): MutableListIterator<T> = list.listIterator()

    override fun listIterator(index: Int): MutableListIterator<T> = list.listIterator(index)

    override fun subList(fromIndex: Int, toIndex: Int): MutableList<T> = list.subList(fromIndex, toIndex)

    override fun add(element: T): Boolean = list.add(element).also { notifyAll() }

    override fun add(index: Int, element: T) = list.add(index, element).also { notifyAll() }

    override fun addAll(index: Int, elements: Collection<T>): Boolean = list
        .addAll(index, elements)
        .also { notifyAll() }

    override fun addAll(elements: Collection<T>): Boolean = list.addAll(elements).also { notifyAll() }

    override fun clear() {
        destroy()
    }

    fun empty() {
        list.clear()
    }

    override fun remove(element: T): Boolean = list.remove(element).also { notifyAll() }

    override fun removeAll(elements: Collection<T>): Boolean = list.removeAll(elements).also { notifyAll() }

    override fun removeAt(index: Int): T = list.removeAt(index).also { notifyAll() }

    override fun retainAll(elements: Collection<T>): Boolean = list.retainAll(elements).also { notifyAll() }

    override fun set(index: Int, element: T): T = list.set(index, element).also { notifyAll() }

    operator fun plusAssign(elements: Collection<T>) {
        addAll(elements)
    }

    fun getAll(): List<T> = list

    override fun destroy() {
        listeners.clear()
        list.clear()
        notifyAll()
    }
}
