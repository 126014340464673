package models.devices.controller.event.process

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import models.devices.controller.event.EventData
import models.devices.controller.event.EventData.Companion.PROCESS_V1
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
@SerialName(PROCESS_V1)
data class ProcessEventV1(

    @SerialName("n")
    val name: String,

    @SerialName("sp")
    val subPhase: ProcessEventV1SubPhase,
) : EventData() {
    fun getLogText(): String {
        return "ProcessEventV1(n=$name, subPhase(n=${subPhase.name}, tl=${subPhase.timeLeft}, rc=${subPhase.runCount}, mc=${subPhase.maxCount}))"
    }
}
