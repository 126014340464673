package admin.models.websocket

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import websocket.WsAdminData

@Model(ADMIN)
@Serializable
data class Ws2Server(

    @SerialName("a")
    val action: WsAdminAction,

    @SerialName("t")
    val authToken: String,

    @SerialName("s")
    val serialNumber: String = "",

    @SerialName("dt")
    val deviceType: Byte = -1,

    @SerialName("d")
    val data: WsAdminData? = null,

    @SerialName("x")
    val additionalField: String = "",

)
