package admin.ui.devices.updates.windows.info

import kotlinx.browser.window
import kotlinx.coroutines.await
import models.update.Update
import navigator.html.DynamicHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLTableElement

class DeviceUpdateInfoWindowView(
    private val update: Update,
    private val onCloseClicked: () -> Unit,
) : DynamicHtml() {

    private lateinit var view: String
    private lateinit var viewModel: DeviceUpdateInfoWindowViewModel

    suspend fun createView(): String {
        view = window
            .fetch("${window.location.origin}$WINDOW_HTML_PATH")
            .then { it.text() }.await().toString()

        viewModel = DeviceUpdateInfoWindowViewModel(update)

        return view
    }

    fun load() {
        ObservableElement<HTMLTableElement>("uuid-cell")
            .bindObservable(viewModel.uuid) { uuid, element -> element.innerHTML = uuid ?: "" }

        ObservableElement<HTMLTableElement>("version-cell")
            .bindObservable(viewModel.version) { version, element -> element.innerHTML = version ?: "" }

        ObservableElement<HTMLTableElement>("createdAt-cell")
            .bindObservable(viewModel.createdAt) { createdAt, element -> element.innerHTML = createdAt ?: "" }

        ObservableElement<HTMLTableElement>("changelog-cell")
            .bindObservable(viewModel.changelog) { changelog, element -> element.innerHTML = changelog ?: "" }

        ObservableButton<HTMLDivElement>("close-button")
            .onClick { onCloseClicked() }
    }

    fun destroy() {
        viewModel.destroy()
    }

    companion object {
        private const val WINDOW_HTML_PATH = "/adminBody/devices/updates/updates_info_window.html"
    }
}
