package models.api.session

import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
data class Credential(val login: String, val password: String)
