package models.devices.controller.settings.client

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsDataCodes.CONTROLLER_CLIENT_SETTINGS_V1

@Model(CORE, CLIENT, ADMIN)
@Serializable
@SerialName(CONTROLLER_CLIENT_SETTINGS_V1)
data class ClientSettingsV1(

    @SerialName("t1")
    val thermometer1: ClientThermometerV1,

    @SerialName("t2")
    val thermometer2: ClientThermometerV1,

    @SerialName("t3")
    val thermometer3: ClientThermometerV1,

    @SerialName("t4")
    val thermometer4: ClientThermometerV1,

    @SerialName("t5")
    val thermometer5: ClientThermometerV1,

    @SerialName("v1")
    val valve1: ClientValveV1,

    @SerialName("v2")
    val valve2: ClientValveV1,

    @SerialName("v3")
    val valve3: ClientValveV1,

    @SerialName("v4")
    val valve4: ClientValveV1,

    @SerialName("v5")
    val valve5: ClientValveV1,

    @SerialName("v6")
    val valve6: ClientValveV1,

    @SerialName("ev1")
    val electricValve1: ClientValveV1,

    @SerialName("ev2")
    val electricValve2: ClientValveV1,

    @SerialName("pv1")
    val precisionValve1: ClientPrecisionValveV1,

    @SerialName("pv2")
    val precisionValve2: ClientPrecisionValveV1,

    @SerialName("pv3")
    val precisionValve3: ClientPrecisionValveV1,

    @SerialName("a")
    val advance: ClientAdvanceSettingsV1,

) : WsClientData, WsAdminData
