package endpoints

import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
object Endpoints {

    const val API = "/api"
    const val ACCESS = "/access"
    const val CLIENT = "/client"
    const val CONTROLLER = "/controller"
    const val THERMOMETERS = "/thermometers"
    const val PUSH = "/push"
    const val SESSION = "/session"
    const val WEBSOCKET = "/websocket"
    const val PING = "/ping"
    const val RECORDS = "/records"
    const val RECORDING = "/recording"
    const val START = "/start"
    const val SAVE = "/save"
    const val RESET = "/reset"
    const val ADMIN = "/admin"
    const val UPDATE = "/update"
    const val UPDATES = "/updates"
    const val SYSTEM = "/system"
    const val MEMORY = "/memory"
    const val HARD_DRIVE = "/hard-drive"
    const val ACCOUNTS = "/accounts"
    const val DEVICES = "/devices"
    const val MEASUREMENTS = "/measurements"
    const val SETTINGS = "/settings"
    const val CONTACT = "/contact"

    const val DEV_DATA = "/data"
    const val DEV_CREATE_ACCOUNT = "/create-account"
    const val DEV_CREATE_CONTROLLER = "/create-controller"
    const val DEV_CLEAR_123 = "/clear-123"
    const val DEV_CLEAR_MONGO_123 = "/clear-mongo-123"
    const val DEV_DOWNLOAD_FILE = "/download-file"
    const val DEV_UPLOAD_FILE = "/upload-file"
    const val DEV_UPLOAD_UPDATE = "/upload-update"
    const val DEV_CLEAR_UPDATES = "/clear-updates"
}
