package navigator.observable.view

import kotlinx.browser.document
import logs.errorLog
import navigator.observable.vm.ObservableValue
import org.w3c.dom.HTMLInputElement

class ObservableInput<T : HTMLInputElement>(private val elementId: String) {

    private var element: T = document.getElementById(elementId).asDynamic() as? T ?: let {
        errorLog(TAG, "missing $elementId")
        throw Throwable("missing element")
    }

    fun onInitialize(action: (T) -> Unit = {}): ObservableInput<T> {
        action(element)
        return this
    }

    fun <OV> bindObservable(observable: ObservableValue<OV>, action: (OV?, T) -> Unit): ObservableInput<T> {
        observable.value?.let { action(it, element) } // reload view
        observable.onChanged { action(it, element) }
        return this
    }

    /* Invoke when selecting value is done */
    fun onChange(action: (String, T) -> Unit = { _, _ -> }): ObservableInput<T> {
        element.onchange = { action(element.value, element) }
        return this
    }

    /* Invoke during pressing */
    fun onInput(action: (String, T) -> Unit = { _, _ -> }): ObservableInput<T> {
        element.oninput = { action(element.value, element) }
        return this
    }

    fun onCheckChange(action: (Boolean, T) -> Unit = { _, _ -> }): ObservableInput<T> {
        element.onchange = { action(element.checked, element) }
        return this
    }

    fun bindEnable(isEnable: ObservableValue<Boolean>, action: (Boolean?, T) -> Unit): ObservableInput<T> {
        isEnable.value?.let { action(it, element) } // reload view
        isEnable.onChanged { action(it, element) }
        return this
    }

    fun bindEnables(
        vararg conditions: ObservableValue<*>,
        action: (List<Any>, T) -> Unit,
    ): ObservableInput<T> {
        conditions
            .mapNotNull { it.value }
            .takeIf { it.size == conditions.size }
            ?.let { values -> action(values, element) }

        conditions.forEach { condition ->
            condition.onChanged {
                conditions
                    .mapNotNull { it.value }
                    .takeIf { it.size == conditions.size }
                    ?.let { values -> action(values, element) }
            }
        }

        return this
    }

    companion object {
        private const val TAG = "[OBSERVABLE]"
    }
}
