package navigator

import kotlinx.browser.window
import logs.debugLog
import navigator.view.LoadResult
import navigator.view.ViewNode
import tools.co

class Navigator(
    private val rootUrlBit: String,
    private val urlBitsAvailableForAuthorized: List<String>,
) {

    private lateinit var mainView: ViewNode

    /** Used to run only one navigate operation until finished. */
    private var isBlocked: Boolean = false

    /** Used for blocking all history operation when back button is clicked. */
    private var isBackMode: Boolean = false

    private var currentUrl: List<String> = emptyList()

    fun initialize(mainView: ViewNode) = co {
        debugLog(TAG, "Initializing...")
        this.mainView = mainView

        window.onpopstate = {
            co { // on user clicked browser back button
                isBackMode = true
                navigate(getAddressUrl())
                isBackMode = false
            }
        }

        isBlocked = true
        val loadResult = mainView.onLoad(listOf())
        val addressUrl = getAddressUrl()
        isBlocked = false

        return@co when {
            isAuthorized(loadResult) -> navigate(addressUrl)
            isUnauthorizedForUserContent(addressUrl) -> navigate(listOf(rootUrlBit))
            isMainPage(addressUrl) -> navigate(listOf(rootUrlBit))
            else -> navigate(addressUrl)
        }
    }

    suspend fun navigate(addressUrl: List<String>) {
        if (isBlocked) return
        if (addressUrl == currentUrl) return
        debugLog(TAG, "Navigate to: $addressUrl")
        isBlocked = true
        updateUrlHistory(addressUrl)
        mainView.navigateTo(addressUrl)
        currentUrl = addressUrl
        isBlocked = false
    }

    fun getAddressUrl(url: String = window.document.URL): List<String> {
        val endpoint = url.substringAfterLast(".")
        return if (endpoint.contains("/")) {
            endpoint.substringAfter("/").split("/").filter { it.isNotEmpty() }
        } else {
            emptyList()
        }
    }

    fun updateUrlHistory(addressUrl: List<String>, replace: Boolean = false) {
        if (isBackMode) return
        val newPath = "${window.location.origin}/${addressUrl.joinToString("/")}"

        if (replace) {
            window.history.replaceState(null, addressUrl.last(), newPath)
        } else {
            window.history.pushState(null, addressUrl.last(), newPath)
        }
    }

    private fun isAuthorized(loadResult: LoadResult) = loadResult.isSuccess

    private fun isUnauthorizedForUserContent(addressUrl: List<String>) = urlBitsAvailableForAuthorized
        .contains(addressUrl.getOrNull(0))

    private fun isMainPage(addressUrl: List<String>) = addressUrl.isEmpty()

    companion object {
        private const val TAG = "[NAVIGATOR]"
    }
}
