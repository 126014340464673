package admin.ui.devices.updates

import admin.services.updates.UpdatesService
import models.update.Update
import navigator.observable.vm.ObservableList
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel
import tools.VersionTools

class DevicesUpdatesViewModel(private val updatesService: UpdatesService) : ViewModel() {

    val isControllersSectionOpen = ObservableValue(false)
    val isThermometersSectionOpen = ObservableValue(false)

    val controllerHCS40UpdatesViewModels = ObservableList<Update>()
    val thermometersT23UpdatesViewModels = ObservableList<Update>()

    suspend fun refresh() {
        controllerHCS40UpdatesViewModels.empty()
        controllerHCS40UpdatesViewModels += updatesService
            .listAsync("controller", "HCS40").await()

        thermometersT23UpdatesViewModels.empty()
        thermometersT23UpdatesViewModels += updatesService
            .listAsync("thermometers", "T23").await()
    }

    suspend fun deleteRecord(uuid: String) {
        updatesService.removeUpdateAsync(uuid).await()
    }

    fun getHighestVersionOrEmpty(): String {
        return try {
            VersionTools.getHighestUpdate(controllerHCS40UpdatesViewModels).version.name()
        } catch (e: Exception) {
            ""
        }
    }
}
