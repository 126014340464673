package admin.ui.devices.manager.edit

import admin.services.api.DeviceApiService
import admin.services.auth.AuthService
import kotlinx.browser.window
import kotlinx.coroutines.await
import logs.infoLog
import models.devices.Device
import navigator.html.DynamicHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableInput
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import tools.co

class DeviceEditWindowView(
    private val device: Device,
    private val authService: AuthService,
    private val deviceApiService: DeviceApiService,
    private val onDeleteClicked: () -> Unit,
    private val onSaveClicked: () -> Unit,
    private val onCancelClicked: () -> Unit,
) : DynamicHtml() {

    private lateinit var view: String
    private lateinit var viewModel: DeviceEditWindowViewModel

    suspend fun createView(): String {
        view = window
            .fetch("${window.location.origin}$WINDOW_HTML_PATH")
            .then { it.text() }.await().toString()

        viewModel = DeviceEditWindowViewModel(device, authService, deviceApiService)

        return view
    }

    fun load() {
        ObservableInput<HTMLInputElement>("device-manager-add-type-name")
            .bindObservable(viewModel.typeId) { value, element -> element.value = value.toString() }
            .onInput { value, _ -> viewModel.typeId.value = value.toByteOrNull() }

        ObservableInput<HTMLInputElement>("device-manager-add-model-name")
            .bindObservable(viewModel.model) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.model.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-password-name")
            .bindObservable(viewModel.password) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.password.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-sv-name")
            .bindObservable(viewModel.softwareVersion) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.softwareVersion.value = value }

        ObservableInput<HTMLInputElement>("device-manager-add-hv-name")
            .bindObservable(viewModel.hardwareVersion) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.hardwareVersion.value = value }

        ObservableButton<HTMLDivElement>("device-manager-edit-save-button")
            .onClick {
                infoLog(TAG, "Clicked Save")
                co { if (viewModel.save()) onSaveClicked() }
            }

        ObservableButton<HTMLDivElement>("device-manager-edit-cancel-button")
            .onClick {
                infoLog(TAG, "Clicked Cancel")
                onCancelClicked()
            }

        ObservableButton<HTMLDivElement>("device-manager-edit-delete-button")
            .onClickCo {
                infoLog(TAG, "Clicked Delete")
                if (viewModel.deleteDevice()) onDeleteClicked()
            }
    }

    fun destroy() {
        viewModel.destroy()
    }

    companion object {
        private const val TAG = "[NEW_ACCOUNT_WINDOW]"
        private const val WINDOW_HTML_PATH = "/adminBody/devices/manager/manager_edit_window.html"
    }
}
