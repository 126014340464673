package models.devices.thermometers.settings.thermometers

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE
import rules.ModelDestination.THERMOMETERS
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsDataCodes.THERMOMETERS_SETTINGS_V1
import websocket.WsThermometersData

@Model(CORE, CLIENT, ADMIN, THERMOMETERS)
@Serializable
@SerialName(THERMOMETERS_SETTINGS_V1)
data class ThermometersSettingsV1(

    @SerialName("t1")
    val thermometer1: ThermometerV1,

    @SerialName("t2")
    val thermometer2: ThermometerV1,

    @SerialName("t3")
    val thermometer3: ThermometerV1,

    @SerialName("t4")
    val thermometer4: ThermometerV1,

    @SerialName("t5")
    val thermometer5: ThermometerV1,

) : WsThermometersData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return "ThermometersSettingsV1(t1(n=${thermometer1.name}), t2(n=${thermometer2.name}), t3(n=${thermometer3.name}), t4(n=${thermometer4.name}), t5(n=${thermometer5.name}))"
    }
}
