package navigator.observable.view

import kotlinx.browser.document
import logs.errorLog
import navigator.observable.vm.ObservableValue
import org.w3c.dom.HTMLTextAreaElement

class ObservableTextArea<T : HTMLTextAreaElement>(private val elementId: String) {

    private var element: T = document.getElementById(elementId).asDynamic() as? T ?: let {
        errorLog(TAG, "missing $elementId")
        throw Throwable("missing element")
    }

    fun <OV> bindObservable(observable: ObservableValue<OV>, action: (OV?, T) -> Unit): ObservableTextArea<T> {
        observable.value?.let { action(it, element) } // reload view
        observable.onChanged { action(it, element) }
        return this
    }

    /* Invoke when selecting value is done */
    fun onChange(action: (String, T) -> Unit = { _, _ -> }): ObservableTextArea<T> {
        element.onchange = { action(element.value, element) }
        return this
    }

    /* Invoke during pressing */
    fun onInput(action: (String, T) -> Unit = { _, _ -> }): ObservableTextArea<T> {
        element.oninput = { action(element.value, element) }
        return this
    }

    companion object {
        private const val TAG = "[OBSERVABLE]"
    }
}
