package models.devices.controller.process

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsControllerData
import websocket.WsDataCodes.CONTROLLER_PROCESS_SETTINGS_V1

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
@SerialName(CONTROLLER_PROCESS_SETTINGS_V1)
data class ProcessSettingsV1(

    /** Power of heater */
    @SerialName("p")
    val power: PowerSettingsV1,

    /** Describe current mode: 0(manual) or 1(auto)  */
    @SerialName("m")
    val mode: Int,

    /** Is open moto valve or electric valve 12 volt, 3 pins */
    @SerialName("v1")
    val valve1: Boolean,

    /** Is open moto valve or electric valve 12 volt, 3 pins */
    @SerialName("v2")
    val valve2: Boolean,

    /** Is open moto valve or electric valve 12 volt, 3 pins */
    @SerialName("v3")
    val valve3: Boolean,

    /** Is open moto valve or electric valve 12 volt, 3 pins */
    @SerialName("v4")
    val valve4: Boolean,

    /** Is open moto valve or electric valve 12 volt, 3 pins */
    @SerialName("v5")
    val valve5: Boolean,

    /** Is open moto valve or electric valve 12 volt, 3 pins */
    @SerialName("v6")
    val valve6: Boolean,

    /** Is open electric valve 230 volt, 2 pins */
    @SerialName("ev1")
    val electricValve1: Boolean,

    /** Is open electric valve 230 volt, 2 pins */
    @SerialName("ev2")
    val electricValve2: Boolean,

    /** Value of spike valve or peristaltic pump */
    @SerialName("pv1")
    val precisionValve1: PrecisionValveV1,

    /** Value of spike valve or peristaltic pump */
    @SerialName("pv2")
    val precisionValve2: PrecisionValveV1,

    /** Value of spike valve or peristaltic pump */
    @SerialName("pv3")
    val precisionValve3: PrecisionValveV1,

) : WsControllerData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return "ProcessSettingsV1(power(v=${power.value}, s=${power.state}), mode=$mode, v1=$valve1, v2=$valve2, v3=$valve3, v4=$valve4, v5=$valve5, v6=$valve6, ev1=$electricValve1, ev2=$electricValve2, pv1=(v=${precisionValve1.value}, s=${precisionValve1.state}), pv2=(v=${precisionValve2.value}, s=${precisionValve2.state}), pv3=(v=${precisionValve3.value}, s=${precisionValve3.state}))"
    }

    companion object {
        private const val MANUAL_MODE = "manual"
        private const val AUTO_MODE = "auto"

        val Default = ProcessSettingsV1(
            power = PowerSettingsV1(value = 0, state = PowerState.OK.value),
            mode = ProcessMode.MANUAL.value,
            valve1 = false,
            valve2 = false,
            valve3 = false,
            valve4 = false,
            valve5 = false,
            valve6 = false,
            electricValve1 = false,
            electricValve2 = false,
            precisionValve1 = PrecisionValveV1(value = 0f, state = PrecisionValveState.UNPLUGGED.value),
            precisionValve2 = PrecisionValveV1(value = 0f, state = PrecisionValveState.UNPLUGGED.value),
            precisionValve3 = PrecisionValveV1(value = 0f, state = PrecisionValveState.UNPLUGGED.value),
        )

        fun createModeByValue(value: Int) = if (value == 0) MANUAL_MODE else AUTO_MODE
    }
}
