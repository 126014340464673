package admin.models.system

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN

@Model(ADMIN)
@Serializable
data class HardDriveInfo(

    /** weight in kB */
    @SerialName("u")
    val usage: Long?,

    /** weight in kB */
    @SerialName("t")
    val total: Long?,

    /** weight in kB */
    @SerialName("l")
    val logs: Long?,
)
