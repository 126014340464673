package admin.ui.devices.updates.windows.info

import models.update.Update
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel
import kotlin.js.Date

class DeviceUpdateInfoWindowViewModel(update: Update) : ViewModel() {

    val version = ObservableValue(update.version.name())
    val uuid = ObservableValue(update.uuid)
    val createdAt = ObservableValue(Date(update.createdAt).toLocaleString())
    val changelog = ObservableValue(update.changelog.joinToString("<br>"))
}
