package tools

import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.launch
import kotlin.time.Duration

fun tryLaunch(success: suspend () -> Unit, failed: suspend (Throwable) -> Unit = {}) = MainScope().launch {
    try {
        success()
    } catch (e: Throwable) {
        failed(e)
    }
}

/** Support: Many subscribers, not keep any data */
fun <T> quickSharedFlow(replay: Int = 0, buffer: Int = 1) = MutableSharedFlow<T>(
    replay = replay,
    extraBufferCapacity = buffer,
    onBufferOverflow = BufferOverflow.DROP_OLDEST,
)

/** Support: Many subscribers, keep last data */
fun <T> bufferSharedFlow(replay: Int = 1, buffer: Int = 1) = MutableSharedFlow<T>(
    replay = replay,
    extraBufferCapacity = buffer,
    onBufferOverflow = BufferOverflow.DROP_OLDEST,
)

fun co(scope: CoroutineScope = MainScope(), action: suspend () -> Unit) = scope.launch { action() }

fun <T> coAsync(scope: CoroutineScope = MainScope(), action: suspend () -> T): Deferred<T> = scope.async { action() }

fun Job.cancelActive() = this.apply { if (isActive) cancel() }

fun CompletableJob.completeActive() = this.apply { if (isActive) complete() }

fun tickerFlow(period: Duration, initialDelay: Duration = Duration.ZERO) = flow {
    delay(initialDelay)
    while (true) {
        emit(Unit)
        delay(period)
    }
}
