package admin.services.cookie

import kotlinx.browser.window

class CookieService {

    operator fun get(key: String) = getAll()[key]

    operator fun set(key: String, value: String) {
        window.document.cookie = "$key=$value"
    }

    fun remove(key: String) {
        window.document.cookie = "$key=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    }

    private fun getAll(): Map<String, String> = window.document.cookie
        .split(";")
        .filter { it.isNotEmpty() }
        .map { item ->
            item.trim().split("=").let { it[0] to it[1] }
        }
        .toMap()

    companion object {
        const val COOKIES_PERMISSION = "cookies"
        const val AUTH_TOKEN = "authToken"
    }
}
