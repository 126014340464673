package models.devices.controller.process

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
data class PowerSettingsV1(

    /** power value of heater, represent in percentage (0-100) */
    @SerialName("v")
    val value: Int,

    /** State of heater, Possible: 0(OK), 1(ERROR) */
    @SerialName("s")
    val state: Int,
)
