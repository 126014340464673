package admin.ui.devices.updates

import admin.services.updates.UpdatesService
import admin.ui.devices.updates.windows.add.DeviceUpdateAddWindowView
import admin.ui.devices.updates.windows.info.DeviceUpdateInfoWindowView
import exntensions.gone
import exntensions.show
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import logs.debugLog
import models.update.Update
import navigator.html.StaticHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableElement
import navigator.view.LoadResult
import navigator.view.View
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTableElement
import tools.co
import tools.getElementById
import kotlin.js.Date

class DevicesUpdatesView(private val updatesService: UpdatesService) :
    StaticHtml(constUrlBit = "updates", resourceUrl = "/adminBody/devices/updates/updates.html"),
    View {

    private val viewModel = DevicesUpdatesViewModel(updatesService)

    // Controller HCS40
    private val controllerHCS40Table get() = getElementById<HTMLTableElement>("controllerHCS40-table")
    private val controllerHCS40items: MutableList<DeviceUpdateRowView> = mutableListOf()

    // Thermometers T23
    private val thermometersT23Table get() = getElementById<HTMLTableElement>("thermometersT23-table")
    private val thermometersT23items: MutableList<DeviceUpdateRowView> = mutableListOf()

    private val windowMountPoint get() = document.getElementById("window-mount-point")?.unsafeCast<HTMLElement>()
    private val addWindow: DeviceUpdateAddWindowView? = null
    private val infoWindow: DeviceUpdateInfoWindowView? = null

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")
        controllerHCS40Table?.innerHTML =
            createView(controllerHCS40items, viewModel.controllerHCS40UpdatesViewModels.getAll())
        thermometersT23Table?.innerHTML =
            createView(thermometersT23items, viewModel.thermometersT23UpdatesViewModels.getAll())

        viewModel.controllerHCS40UpdatesViewModels.onChanged {
            controllerHCS40Table?.innerHTML = createView(controllerHCS40items, it)
            bindElements()
            controllerHCS40items.forEach { it.onLoad() }
        }

        viewModel.thermometersT23UpdatesViewModels.onChanged {
            thermometersT23Table?.innerHTML = createView(thermometersT23items, it)
            bindElements()
            thermometersT23items.forEach { it.onLoad() }
        }

        viewModel.refresh()

        bindElements()

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
        viewModel.refresh()
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        viewModel.destroy()
    }

    private fun bindElements() {
        ObservableButton<HTMLDivElement>("controllers-updates-label")
            .onClick {
                val arrowButton = it.lastElementChild?.lastElementChild?.lastElementChild?.lastElementChild
                if (viewModel.isControllersSectionOpen.value == true) {
                    viewModel.isControllersSectionOpen.value = false
                    arrowButton?.removeClass("expandable-container-show")
                } else {
                    viewModel.isControllersSectionOpen.value = true
                    arrowButton?.addClass("expandable-container-show")
                }
            }

        ObservableElement<HTMLDivElement>("controllers-updates-container")
            .bindObservable(viewModel.isControllersSectionOpen) { isOpen, element ->
                if (isOpen == true) element.show() else element.gone()
            }

        ObservableButton<HTMLDivElement>("controllersHCS40-add-button")
            .onClickCo {
                val addWindow = DeviceUpdateAddWindowView(
                    initVersion = viewModel.getHighestVersionOrEmpty(),
                    device = "controller",
                    model = "HCS40",
                    updatesService = updatesService,
                    onSaveClicked = {
                        co { viewModel.refresh() }
                        windowMountPoint?.innerHTML = ""
                        addWindow?.destroy()
                    },
                    onCloseClicked = {
                        windowMountPoint?.innerHTML = ""
                        addWindow?.destroy()
                    },
                )

                windowMountPoint?.innerHTML = addWindow.createView()
                addWindow.load()
            }

        ObservableButton<HTMLDivElement>("thermometers-updates-label")
            .onClick {
                val arrowButton = it.lastElementChild?.lastElementChild?.lastElementChild?.lastElementChild
                if (viewModel.isThermometersSectionOpen.value == true) {
                    viewModel.isThermometersSectionOpen.value = false
                    arrowButton?.removeClass("expandable-container-show")
                } else {
                    viewModel.isThermometersSectionOpen.value = true
                    arrowButton?.addClass("expandable-container-show")
                }
            }

        ObservableElement<HTMLDivElement>("thermometers-updates-container")
            .bindObservable(viewModel.isThermometersSectionOpen) { isOpen, element ->
                if (isOpen == true) element.show() else element.gone()
            }

        ObservableButton<HTMLDivElement>("thermometersT23-add-button")
            .onClickCo {
                val addWindow = DeviceUpdateAddWindowView(
                    initVersion = viewModel.getHighestVersionOrEmpty(),
                    device = "thermometers",
                    model = "T23",
                    updatesService = updatesService,
                    onSaveClicked = {
                        co { viewModel.refresh() }
                        windowMountPoint?.innerHTML = ""
                        addWindow?.destroy()
                    },
                    onCloseClicked = {
                        windowMountPoint?.innerHTML = ""
                        addWindow?.destroy()
                    },
                )

                windowMountPoint?.innerHTML = addWindow.createView()
                addWindow.load()
            }
    }

    private fun createView(viewItems: MutableList<DeviceUpdateRowView>, updates: List<Update>): String {
        viewItems.clear()
        viewItems += updates.map { DeviceUpdateRowView(it, ::infoUpdate, ::deleteRecord) }
        return createTableHeaderView() + viewItems.joinToString("") { it.createView() }
    }

    private suspend fun infoUpdate(update: Update) {
        if (!windowMountPoint?.innerHTML.isNullOrEmpty()) return

        val infoWindow = DeviceUpdateInfoWindowView(
            update = update,
            onCloseClicked = {
                windowMountPoint?.innerHTML = ""
                infoWindow?.destroy()
            },
        )

        windowMountPoint?.innerHTML = infoWindow.createView()
        infoWindow.load()
    }

    private suspend fun deleteRecord(uuid: String) {
        viewModel.deleteRecord(uuid)
        viewModel.refresh()
    }

    private fun createTableHeaderView(): String {
        return """
            <tr>
                <th class="th"></th>
                <th class="th">Uuid</th>
                <th class="th">Wersja</th>
                <th class="th">Data utworzenia</th>
                <th class="th"></th>
                <th class="th"></th>
            </tr>
        """.trimIndent()
    }

    class DeviceUpdateRowView(
        private val update: Update,
        val infoUpdate: suspend (Update) -> Unit,
        val deleteUpdate: suspend (String) -> Unit,
    ) {
        private val deleteButtonViewId = "device-delete-btn-${update.uuid}"
        private val infoButtonViewId = "device-info-btn-${update.uuid}"

        fun onLoad() {
            ObservableButton<HTMLDivElement>(infoButtonViewId)
                .onClickCo { infoUpdate(update) }

            ObservableButton<HTMLDivElement>(deleteButtonViewId)
                .onClickCo {
                    if (window.confirm("Jesteś pewien")) {
                        deleteUpdate(update.uuid)
                    }
                }
        }

        fun createView(): String {
            val createdAt = Date(update.createdAt).toLocaleString()
            return """
                <tr>
                   <td class="td"></td>
                   <td class="td">${update.uuid}</td>
                   <td class="td">${update.version.name()}</td>
                   <td class="td">$createdAt</td>
                   <td class="td">
                      <div id="$infoButtonViewId" style="float: left;" class="btn-white from-left small-btn">Info</div>
                   </td>
                   <td class="td">
                      <div id="$deleteButtonViewId" style="float: left;" class="btn-white from-left small-btn">Usuń</div>
                   </td>
                </tr>
            """.trimIndent()
        }
    }

    companion object {
        const val TAG = "[UPDATES]"
    }
}
