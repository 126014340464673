package models.devices.controller.settings.controller

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsControllerData
import websocket.WsDataCodes.CONTROLLER_SETTINGS_V1

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
@SerialName(CONTROLLER_SETTINGS_V1)
data class ControllerSettingsV1(

    @SerialName("t1")
    val thermometer1: ThermometerV1,

    @SerialName("t2")
    val thermometer2: ThermometerV1,

    @SerialName("t3")
    val thermometer3: ThermometerV1,

    @SerialName("t4")
    val thermometer4: ThermometerV1,

    @SerialName("t5")
    val thermometer5: ThermometerV1,

    @SerialName("v1")
    val valve1: ValveV1,

    @SerialName("v2")
    val valve2: ValveV1,

    @SerialName("v3")
    val valve3: ValveV1,

    @SerialName("v4")
    val valve4: ValveV1,

    @SerialName("v5")
    val valve5: ValveV1,

    @SerialName("v6")
    val valve6: ValveV1,

    @SerialName("ev1")
    val electricValve1: ValveV1,

    @SerialName("ev2")
    val electricValve2: ValveV1,

    @SerialName("pv1")
    val precisionValve1: ValveV1,

    @SerialName("pv2")
    val precisionValve2: ValveV1,

    @SerialName("pv3")
    val precisionValve3: ValveV1,

) : WsControllerData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return "ControllerSettingsV1(t1(n=${thermometer1.name}, o=${thermometer1.offTemperature}), t2(n=${thermometer2.name}, o=${thermometer2.offTemperature}), t3(n=${thermometer3.name}, o=${thermometer3.offTemperature}), t4(n=${thermometer4.name}, o=${thermometer4.offTemperature}), t5(n=${thermometer5.name}, o=${thermometer5.offTemperature}), v1(n=${valve1.name}), v2(n=${valve2.name}), v3(n=${valve3.name}), v4(n=${valve4.name}), v5(n=${valve5.name}), v6(n=${valve6.name}), ev1(n=${electricValve1.name}), ev2(n=${electricValve2.name}), pv1(n=${precisionValve1.name}), pv2(n=${precisionValve2.name}), pv3(n=${precisionValve3.name}))"
    }
}
