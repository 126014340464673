package admin.ui.users.manager

import admin.services.api.AccountsApiService
import admin.services.auth.AuthService
import extensions.getValue
import models.account.Account
import navigator.observable.vm.ObservableList
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel

class UsersManagerViewModel(
    private val authService: AuthService,
    private val accountsApiService: AccountsApiService,
) : ViewModel() {

    val searchValue = ObservableValue("")
    val accounts = ObservableList<Account>()

    suspend fun refresh() {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at search: ${searchValue.value}.")
            return
        }

        val query = searchValue.value ?: return

        accounts.empty()
        accounts += accountsApiService.listAsync(authToken, query).await()
    }
}
