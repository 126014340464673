package models.update

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, ADMIN, CONTROLLER)
@Serializable
data class Update(

    @SerialName("u")
    val uuid: String,

    @SerialName("v")
    val version: UpdateVersion,

    @SerialName("b")
    val bytes: List<Byte>,

    @SerialName("c")
    val checksum: Int,

    @SerialName("d")
    val device: String,

    @SerialName("m")
    val model: String,

    @SerialName("ca")
    val createdAt: Long,

    @SerialName("cl")
    val changelog: List<String>,

    @SerialName("mv")
    val measurementVersion: Int, // TODO feature is not fully implemented

    @SerialName("pv")
    val processVersion: Int, // TODO feature is not fully implemented

    @SerialName("sv")
    val settingsVersion: Int, // TODO feature is not fully implemented
)
