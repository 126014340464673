package models.devices.thermometers.measurements

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE
import rules.ModelDestination.THERMOMETERS
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsDataCodes.THERMOMETERS_MEASUREMENTS_V1
import websocket.WsThermometersData

@Model(CORE, CLIENT, ADMIN, THERMOMETERS)
@Serializable
@SerialName(THERMOMETERS_MEASUREMENTS_V1)
data class MeasurementV1(

    @SerialName("t")
    val temperatures: TemperaturesV1,

) : WsThermometersData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return "MeasurementV1([t1=${temperatures.temperature1}, t2=${temperatures.temperature2}, t3=${temperatures.temperature3}, t4=${temperatures.temperature4}, t5=${temperatures.temperature5}])"
    }
}
