package admin.services.updates

import admin.services.api.UpdatesApiService
import admin.services.api.models.UpdateRequest
import admin.services.auth.AuthService
import extensions.getValue
import kotlinx.coroutines.Deferred
import models.update.Update

class UpdatesServiceImpl(
    private val authService: AuthService,
    private val apiService: UpdatesApiService,
) : UpdatesService {

    override suspend fun listAsync(device: String, model: String): Deferred<List<Update>> {
        val authToken = authService.tokenFlow.getValue() ?: throw NullPointerException()
        return apiService.listAsync(authToken, device, model)
    }

    override suspend fun newUpdateAsync(updateRequest: UpdateRequest): Deferred<Unit> {
        val authToken = authService.tokenFlow.getValue() ?: throw NullPointerException()
        return apiService.newUpdateAsync(authToken, updateRequest)
    }

    override suspend fun removeUpdateAsync(uuid: String): Deferred<Unit> {
        val authToken = authService.tokenFlow.getValue() ?: throw NullPointerException()
        return apiService.removeUpdateAsync(authToken, uuid)
    }
}
