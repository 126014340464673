package navigator.observable.view

import kotlinx.browser.document
import logs.errorLog
import navigator.observable.vm.ObservableList
import navigator.observable.vm.ObservableValue
import org.w3c.dom.Element
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit

class ObservableElement<T : Element>(private val elementId: String) {

    private var element: T = document.getElementById(elementId).asDynamic() as? T ?: let {
        errorLog(TAG, "missing $elementId")
        throw Throwable("missing element")
    }

    fun onInitialize(action: (T) -> Unit = {}): ObservableElement<T> {
        action(element)
        return this
    }

    fun bindInnerHtml(observableValue: ObservableValue<String>, action: (T) -> Unit = {}): ObservableElement<T> {
        val observer = MutationObserver { _, _ ->
            element.innerHTML
                .takeIf { newValue -> newValue != observableValue.value }
                ?.let { newValue -> observableValue.value = newValue }
        }

        observableValue.value?.let { element.innerHTML = it } // reload view
        action(element)

        observer.observe(element, MutationObserverInit(childList = true))
        observableValue.onChanged { newValue ->
            element.innerHTML = newValue ?: ""
            action(element)
        }
        return this
    }

    fun bindEnable(isEnable: ObservableValue<Boolean>, action: (Boolean?, T) -> Unit): ObservableElement<T> {
        isEnable.value?.let { action(it, element) } // reload view
        isEnable.onChanged { action(it, element) }
        return this
    }

    fun <OV> bindObservable(observable: ObservableValue<OV>, action: (OV?, T) -> Unit): ObservableElement<T> {
        observable.value?.let { action(it, element) } // reload view
        observable.onChanged { action(it, element) }
        return this
    }

    fun <OV> bindObservable(observableList: ObservableList<OV>, action: (List<OV>, T) -> Unit): ObservableElement<T> {
        action(observableList, element)
        observableList.onChanged { action(it, element) }

        return this
    }

    fun bindObservables(
        vararg conditions: ObservableValue<*>,
        action: (List<Any>, T) -> Unit,
    ): ObservableElement<T> {
        conditions
            .mapNotNull { it.value }
            .takeIf { it.size == conditions.size }
            ?.let { values -> action(values, element) }

        conditions.forEach { condition ->
            condition.onChanged {
                conditions
                    .mapNotNull { it.value }
                    .takeIf { it.size == conditions.size }
                    ?.let { values -> action(values, element) }
            }
        }

        return this
    }

    fun <OV> bindNullableObservable(observable: ObservableValue<OV>, action: (OV?, T) -> Unit): ObservableElement<T> {
        action(observable.value, element) // reload view
        observable.onChanged { action(it, element) }
        return this
    }

    fun apply(action: (T) -> Unit = {}) = action(element)

    companion object {
        private const val TAG = "[OBSERVABLE]"
    }
}
