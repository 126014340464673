package navigator.observable.view

import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import logs.errorLog
import navigator.observable.vm.ObservableList
import navigator.observable.vm.ObservableValue
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import org.w3c.dom.HTMLSpanElement
import org.w3c.dom.get

class InputText(elementId: String) {

    val value: String get() = getInputElement().value
    val input: HTMLInputElement get() = getInputElement()

    private var element: HTMLDivElement = document.getElementById(elementId).asDynamic() as? HTMLDivElement ?: let {
        errorLog("[HTML]", "missing $elementId")
        throw Throwable("missing element")
    }

    // region Custom
    fun <OV> bindObservable(
        observable: ObservableValue<OV>,
        action: (OV?, HTMLInputElement, HTMLSpanElement, HTMLSpanElement) -> Unit,
    ): InputText {
        action(observable.value, getInputElement(), getLabelElement(), getHelperElement())
        observable.onChanged { action(it, getInputElement(), getLabelElement(), getHelperElement()) }
        return this
    }

    fun <OV> bindObservable(
        observables: ObservableList<OV>,
        action: (List<OV>, HTMLInputElement, HTMLSpanElement, HTMLSpanElement) -> Unit,
    ): InputText {
        action(observables, getInputElement(), getLabelElement(), getHelperElement())
        observables.onChanged { action(it, getInputElement(), getLabelElement(), getHelperElement()) }
        return this
    }

    fun bindObservables(
        vararg observables: ObservableValue<*>,
        action: (List<Any>, HTMLInputElement, HTMLSpanElement, HTMLSpanElement) -> Unit,
    ): InputText {
        notifyAll(observables = observables, action = action)
        observables.forEach { it.onChanged { notifyAll(observables = observables, action = action) } }
        return this
    }
    // endregion

    fun setHelperText(text: String) {
        getHelperElement().innerHTML = text
    }

    fun setLabel(label: String): InputText {
        getLabelElement().innerHTML = label
        return this
    }

    fun bindError(observable: ObservableValue<String>): InputText {
        setStyle(observable.value != null)
        getHelperElement().innerHTML = observable.value ?: ""

        observable.onChanged {
            setStyle(observable.value != null)
            getHelperElement().innerHTML = observable.value ?: ""
        }

        return this
    }

    fun bindErrorStyle(observable: ObservableValue<Boolean>): InputText {
        setStyle(observable.value == true)
        observable.onChanged { setStyle(observable.value == true) }

        return this
    }

    fun bindHelper(observable: ObservableValue<String>): InputText {
        getHelperElement().innerHTML = observable.value ?: ""
        observable.onChanged { getHelperElement().innerHTML = observable.value ?: "" }
        return this
    }

    fun bindText(observable: ObservableValue<String>): InputText {
        getInputElement().apply {
            value = observable.value ?: ""
            oninput = {
                if (observable.value != value) observable.value = value
                Unit
            }
        }
        observable.onChanged {
            getInputElement().apply {
                value = observable.value ?: ""
                oninput = {
                    if (observable.value != value) observable.value = value
                    Unit
                }
            }
        }
        return this
    }

    fun bindEnable(observable: ObservableValue<Boolean>): InputText {
        getInputElement().disabled = observable.value ?: false
        observable.onChanged { getInputElement().disabled = observable.value ?: false }
        return this
    }

    private fun notifyAll(
        vararg observables: ObservableValue<*>,
        action: (List<Any>, HTMLInputElement, HTMLSpanElement, HTMLSpanElement) -> Unit,
    ) {
        observables
            .mapNotNull { it.value }
            .takeIf { it.size == observables.size }
            ?.let { values -> action(values, getInputElement(), getLabelElement(), getHelperElement()) }
    }

    fun setStyle(isError: Boolean) {
        if (isError) {
            getRootElement().addClass("input-text-danger")
        } else {
            getRootElement().removeClass("input-text-danger")
        }
    }

    /** Error style(input-text-danger) isn't working properly with inbuilt validation like type="email", pattern="[a-z]", etc. */
    fun setError(text: String) {
        getRootElement().addClass("input-text-danger")
        getHelperElement().innerHTML = text
    }

    fun cleanError() {
        getRootElement().removeClass("input-text-danger")
        getHelperElement().innerHTML = ""
    }

    private fun getRootElement(): HTMLLabelElement = element.children[0]?.children?.get(0) as HTMLLabelElement

    private fun getInputElement(): HTMLInputElement = getRootElement().children[0] as HTMLInputElement

    private fun getLabelElement(): HTMLSpanElement = getRootElement().children[1] as HTMLSpanElement

    private fun getHelperElement(): HTMLSpanElement = getRootElement().children[2] as HTMLSpanElement
}
