package admin.ui.users.manager.windows.edit

import admin.services.api.AccessApiService
import admin.services.api.AccountsApiService
import admin.services.auth.AuthService
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import logs.infoLog
import models.access.Access
import models.account.Account
import navigator.html.DynamicHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableInput
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTableElement
import tools.co
import tools.getElementById

class UserEditWindowView(
    private val authService: AuthService,
    private val accountsApiService: AccountsApiService,
    private val accessApiService: AccessApiService,
    private val account: Account,
    private val onDeleteClicked: () -> Unit,
    private val onSaveClicked: () -> Unit,
    private val onCancelClicked: () -> Unit,
) : DynamicHtml() {

    private lateinit var view: String
    private lateinit var viewModel: UserEditWindowViewModel

    private val accessTable get() = getElementById<HTMLTableElement>("user-manager-edit-accesses-table")
    private val accessItems: MutableList<AccessRowView> = mutableListOf()

    suspend fun createView(): String {
        view = window
            .fetch("${window.location.origin}$WINDOW_HTML_PATH")
            .then { it.text() }.await().toString()

        viewModel = UserEditWindowViewModel(account, authService, accountsApiService, accessApiService)

        viewModel.accesses.onChanged {
            co {
                delay(200) // hack, somehow accessTable is null
                accessTable?.innerHTML = createTableView()
                accessItems.forEach { it.onLoad() }
            }
        }

        viewModel.refresh()

        return view
    }

    fun load() {
        ObservableInput<HTMLInputElement>("user-manager-edit-login-name")
            .bindObservable(viewModel.login) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.login.value = value }

        ObservableInput<HTMLInputElement>("user-manager-edit-password-name")
            .bindObservable(viewModel.password) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.password.value = value }

        ObservableInput<HTMLInputElement>("user-manager-edit-email-name")
            .bindObservable(viewModel.email) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.email.value = value }

        ObservableButton<HTMLDivElement>("user-manager-edit-save-button")
            .onClick {
                infoLog(TAG, "Clicked Save")
                co { if (viewModel.save()) onSaveClicked() }
            }

        ObservableButton<HTMLDivElement>("user-manager-edit-cancel-button")
            .onClick {
                infoLog(TAG, "Clicked Cancel")
                onCancelClicked()
            }

        ObservableButton<HTMLDivElement>("user-manager-edit-delete-button")
            .onClickCo {
                infoLog(TAG, "Clicked Delete")
                if (viewModel.deleteAccount()) onDeleteClicked()
            }
    }

    fun destroy() {
        viewModel.destroy()
    }

    private fun createTableView(): String {
        accessItems.clear()
        accessItems += viewModel.accesses
            .map { AccessRowView(it, ::deleteAccess) }
        return createTableHeaderView() + accessItems.joinToString("") { it.createView() }
    }

    private suspend fun deleteAccess(access: Access) {
        if (viewModel.deleteAccess(access.uuid)) viewModel.refresh()
    }

    private fun createTableHeaderView(): String {
        return """
            <tr>
                <th class="th"></th>
                <th class="th">Uuid</th>
                <th class="th">SerialNumber</th>
                <th class="th">Name</th>
                <th class="th">Ulubiony</th>
                <th class="th">Pozycja</th>
                <th class="th">Typ</th>
                <th class="th"></th>
            </tr>
        """.trimIndent()
    }

    class AccessRowView(
        private val access: Access,
        val deleteAccess: suspend (Access) -> Unit,
    ) {
        private val deleteButtonViewId = "user-manager-access-delete-btn-${access.uuid}"

        fun onLoad() {
            ObservableButton<HTMLDivElement>(deleteButtonViewId)
                .onClickCo { deleteAccess(access) }
        }

        fun createView(): String {
            return """
             <tr>
               <td class="td"></td>
               <td class="td">${access.uuid}</td>
               <td class="td">${access.device.serialNumber}</td>
               <td class="td">${access.name}</td>
               <td class="td">${access.isFavourite}</td>
               <td class="td">${access.position}</td>
               <td class="td">${access.type}</td>
               <td class="td">
                 <div id="$deleteButtonViewId" style="float: left;" class="btn-white from-left small-btn">Usuń</div>
               </td>
            </tr>
            """.trimIndent()
        }
    }

    companion object {
        private const val TAG = "[NEW_ACCOUNT_WINDOW]"
        private const val WINDOW_HTML_PATH = "/adminBody/users/manager/manager_edit_window.html"
    }
}
