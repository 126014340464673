package models.devices.controller.settings.client

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
data class ClientPrecisionValveV1(

    @SerialName("iv")
    val isVisible: Boolean,

    /** Max 7 values from 0 to 100 */
    @SerialName("f")
    val favourites: List<Float>,
)
