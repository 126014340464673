package admin.ui.login

import admin.services.auth.AuthService
import admin.services.cookie.CookieService
import kotlinx.browser.window
import logs.debugLog
import logs.infoLog
import navigator.html.StaticHtml
import navigator.observable.view.InputText
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableElement
import navigator.view.LoadResult
import navigator.view.View
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.svg.SVGElement

class LoginView(
    authService: AuthService,
    cookieService: CookieService,
) : StaticHtml(constUrlBit = "login", resourceUrl = "/adminBody/login/login.html"), View {

    private val viewModel = LoginViewModel(
        authService = authService,
        cookieService = cookieService,
    )

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")

        InputText("login")
            .setLabel("Login")
            .bindText(viewModel.user)
            .bindErrorStyle(viewModel.isLoginError)

        InputText("password")
            .setLabel("Password")
            .bindText(viewModel.password)
            .bindErrorStyle(viewModel.isLoginError)
            .bindObservable(viewModel.isPasswordVisible) { isVisible, input, _, _ -> setInputType(isVisible, input) }

        ObservableElement<HTMLDivElement>("login-error")
            .bindObservable(viewModel.isLoginError) { isError, element -> setHelperText(isError, element) }

        ObservableElement<SVGElement>("password-visibility-button")
            .apply { element ->
                element.onmouseenter = { viewModel.isPasswordVisible.value = true; Unit }
                element.onmouseleave = { viewModel.isPasswordVisible.value = false; Unit }
            }

        ObservableButton<HTMLDivElement>("login-button-confirm")
            .onClick {
                infoLog(TAG, "Login ${viewModel.user.value}")
                if (viewModel.hasPermissionToCookies()) {
                    viewModel.login()
                } else if (window.confirm(PERMISSION_INFO)) {
                    viewModel.acceptCookies()
                    viewModel.login()
                }
            }

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        viewModel.destroy()
    }

    private fun setHelperText(isError: Boolean?, element: Element) {
        element.innerHTML = if (isError == true) LOGIN_ERROR else ""
    }

    private fun setInputType(isVisible: Boolean?, element: HTMLInputElement) {
        element.type = if (isVisible == true) "text" else "password"
    }

    companion object {
        const val TAG = "[LOGIN]"

        private const val PERMISSION_INFO = "Korzystanie z witryny oznacza zgodę na wykorzystywanie plików cookie."
        private const val LOGIN_ERROR = "Niepoprawny login lub hasło."
    }
}
