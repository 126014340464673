package admin.ui.devices.manager

import admin.services.api.DeviceApiService
import admin.services.auth.AuthService
import admin.ui.devices.manager.add.DeviceAddWindowView
import admin.ui.devices.manager.edit.DeviceEditWindowView
import kotlinx.browser.document
import logs.debugLog
import models.devices.Device
import navigator.html.StaticHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableInput
import navigator.view.LoadResult
import navigator.view.View
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTableElement
import tools.co
import tools.getElementById

class DevicesManagerView(
    private val authService: AuthService,
    private val deviceApiService: DeviceApiService,
) : StaticHtml(constUrlBit = "manager", resourceUrl = "/adminBody/devices/manager/manager.html"), View {

    private val viewModel: DevicesManagerViewModel = DevicesManagerViewModel(authService, deviceApiService)

    private val devicesTable get() = getElementById<HTMLTableElement>("device-manager-devices-table")
    private val devicesItems: MutableList<DeviceRowView> = mutableListOf()

    private val windowMountPoint get() = document.getElementById("window-mount-point")?.unsafeCast<HTMLElement>()
    private val addWindow: DeviceAddWindowView? = null
    private val editWindow: DeviceEditWindowView? = null

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")
        devicesTable?.innerHTML = createView()

        viewModel.devices.onChanged {
            devicesTable?.innerHTML = createView()
            devicesItems.forEach { it.onLoad() }
        }

        bindElements()

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        viewModel.destroy()
    }

    private fun bindElements() {
        ObservableInput<HTMLInputElement>("device-manager-search-input")
            .bindObservable(viewModel.searchValue) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.searchValue.value = value }

        ObservableButton<HTMLButtonElement>("device-manager-search-button")
            .onClickCo { viewModel.refresh() }

        ObservableButton<HTMLButtonElement>("device-manager-add-button")
            .onClickCo { addAccountWindow() }
    }

    private fun createView(): String {
        devicesItems.clear()
        devicesItems += viewModel.devices
            .map { DeviceRowView(it, ::editDeviceWindow) }
        return createTableHeaderView() + devicesItems.joinToString("") { it.createView() }
    }

    private fun createTableHeaderView(): String {
        return """
            <tr>
                <th class="th"></th>
                <th class="th">SerialNumber</th>
                <th class="th">Type</th>
                <th class="th">Model</th>
                <th class="th">Password</th>
                <th class="th">SoftwareVersion</th>
                <th class="th">HardwareVersion</th>
                <th class="th"></th>
            </tr>
        """.trimIndent()
    }

    private suspend fun editDeviceWindow(device: Device) {
        if (!windowMountPoint?.innerHTML.isNullOrEmpty()) return

        val editWindow = DeviceEditWindowView(
            device = device,
            authService = authService,
            deviceApiService = deviceApiService,
            onCancelClicked = {
                windowMountPoint?.innerHTML = ""
                editWindow?.destroy()
            },
            onSaveClicked = {
                co { viewModel.refresh() }
                windowMountPoint?.innerHTML = ""
                editWindow?.destroy()
            },
            onDeleteClicked = {
                co { viewModel.refresh() }
                windowMountPoint?.innerHTML = ""
                editWindow?.destroy()
            },
        )

        windowMountPoint?.innerHTML = editWindow.createView()
        editWindow.load()
    }

    private suspend fun addAccountWindow() {
        if (!windowMountPoint?.innerHTML.isNullOrEmpty()) return

        val addWindow = DeviceAddWindowView(
            authService = authService,
            deviceApiService = deviceApiService,
            onCancelClicked = {
                windowMountPoint?.innerHTML = ""
                addWindow?.destroy()
            },
            onSaveClicked = {
                co { viewModel.refresh() }
                windowMountPoint?.innerHTML = ""
                addWindow?.destroy()
            },
        )

        windowMountPoint?.innerHTML = addWindow.createView()
        addWindow.load()
    }

    class DeviceRowView(
        private val device: Device,
        val edit: suspend (Device) -> Unit,
    ) {
        private val editButtonViewId = "device-manager-edit-btn-${device.serialNumber}"

        fun onLoad() {
            ObservableButton<HTMLDivElement>(editButtonViewId)
                .onClickCo { edit(device) }
        }

        fun createView(): String {
            return """
                <tr>
                  <td class="td"></td>
                  <td class="td">${device.serialNumber}</td>
                  <td class="td">${device.type.id} (${device.type.name.lowercase()})</td>
                  <td class="td">${device.model}</td>
                  <td class="td">${device.password}</td>
                  <td class="td">${device.softwareVersion}</td>
                  <td class="td">${device.hardwareVersion}</td>
                  <td class="td">
                      <div id="$editButtonViewId" style="float: left;" class="btn-white from-left small-btn">Edytuj</div>
                  </td>
                </tr>
            """.trimIndent()
        }
    }

    companion object {
        const val TAG = "[MANAGER]"
    }
}
