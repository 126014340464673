package models.devices.controller.event.process

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
data class ProcessEventV1SubPhase(

    @SerialName("n")
    val name: String,

    @SerialName("tl")
    val timeLeft: Long,

    @SerialName("rc")
    val runCount: Int,

    @SerialName("mc")
    val maxCount: Int,
)
