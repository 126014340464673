package admin.ui.devices.manager

import admin.services.api.DeviceApiService
import admin.services.auth.AuthService
import extensions.getValue
import models.devices.Device
import navigator.observable.vm.ObservableList
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel

class DevicesManagerViewModel(
    private val authService: AuthService,
    private val deviceApiService: DeviceApiService,
) : ViewModel() {

    val searchValue = ObservableValue("")
    val devices = ObservableList<Device>()

    suspend fun refresh() {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("No auth token at search: ${searchValue.value}.")
            return
        }

        val serialNumber = searchValue.value ?: return

        devices.empty()
        devices += deviceApiService.listAsync(authToken, serialNumber).await()
    }
}
