package admin.ui.users

import admin.services.Services
import admin.ui.users.manager.UsersManagerView
import exntensions.boldFont
import exntensions.normalFont
import kotlinx.browser.document
import logs.debugLog
import navigator.Navigator
import navigator.html.StaticHtml
import navigator.observable.view.ObservableButton
import navigator.view.LoadResult
import navigator.view.View
import navigator.view.ViewNode
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLUListElement

class UsersView(
    override val navigator: Navigator,
    val services: Services,
) : StaticHtml(constUrlBit = "users", resourceUrl = "/adminBody/users/users.html"), ViewNode {

    override val urlBitIndex: Int = 1
    override val contentViewId: String = "users-content"
    override var htmlView: StaticHtml? = null

    private val viewModel = UsersViewModel()

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")

        addressUrl.getOrNull(urlBitIndex)?.let { viewModel.initialize(it) }

        bindElements()

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
        addressUrl.getOrNull(urlBitIndex)?.let { viewModel.reloadView(it) }
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        (htmlView as? View)?.onDestroy()
    }

    override suspend fun navigateTo(addressUrl: List<String>) {
        debugLog(TAG, "navigate($addressUrl)")

        if (addressUrl.size > 1) {
            val urlBit = addressUrl[urlBitIndex]
            debugLog(TAG, "navigate($urlBit)")
            run(addressUrl, urlBit)
        }
    }

    override suspend fun buildView(addressUrl: List<String>, urlBit: String) {
        debugLog(TAG, "View building: $urlBit")

        htmlView = when (urlBit) {
            "manager" -> UsersManagerView(services.auth, services.api.accounts, services.api.access)
            else -> throw Exception("Invalid path for $urlBit at layer 1.")
        }

        document.getElementById(contentViewId)
            ?.unsafeCast<HTMLElement>()
            ?.innerHTML = htmlView?.getView()!!

        navigator.updateUrlHistory(addressUrl, replace = true)
    }

    private fun bindElements() {
        ObservableButton<HTMLUListElement>("manager-button")
            .bindObservable(viewModel.currentView) { value, element ->
                if (value == "manager") element.boldFont() else element.normalFont()
            }
            .onClickCo {
                viewModel.currentView.value = "manager"
                navigator.navigate(listOf("users", "manager"))
            }
    }

    companion object {
        const val TAG = "[USERS]"
    }
}
