package admin.serialization

import admin.models.system.Cpu
import admin.models.system.Memory
import admin.models.system.Network
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.plus
import models.devices.Device
import models.devices.connection.OpenConnectionV1
import models.devices.controller.event.EventData
import models.devices.controller.event.alert.OffTempAlertEventV1
import models.devices.controller.event.process.ProcessEventV1
import models.devices.controller.logs.DeviceLogV1
import rules.Model
import rules.ModelDestination.ADMIN
import websocket.WsAdminData
import models.devices.controller.event.EventV1 as CEventV1
import models.devices.controller.measurements.MeasurementV1 as CMeasurementV1
import models.devices.controller.process.ProcessSettingsV1 as CProcessSettingsV1
import models.devices.controller.settings.auto.AutoSettingsV1 as CAutoSettingsV1
import models.devices.controller.settings.client.ClientSettingsV1 as CClientSettingsV1
import models.devices.controller.settings.controller.ControllerSettingsV1 as CControllerSettingsV1
import models.devices.controller.settings.stabilization.StabilizationSettingsV1 as CStabilizationSettingsV1
import models.devices.thermometers.measurements.MeasurementV1 as TMeasurementV1
import models.devices.thermometers.settings.client.ClientSettingsV1 as TClientSettingsV1
import models.devices.thermometers.settings.stabilization.StabilizationSettingsV1 as TStabilizationSettingsV1
import models.devices.thermometers.settings.thermometers.ThermometersSettingsV1 as ThermometersSettingsV1

val webSocketDataModule = SerializersModule {
    polymorphic(WsAdminData::class, OpenConnectionV1::class, OpenConnectionV1.serializer())
    polymorphic(WsAdminData::class, Network::class, Network.serializer())
    polymorphic(WsAdminData::class, Cpu::class, Cpu.serializer())
    polymorphic(WsAdminData::class, Memory::class, Memory.serializer())
    polymorphic(WsAdminData::class, Device::class, Device.serializer())
    polymorphic(WsAdminData::class, DeviceLogV1::class, DeviceLogV1.serializer())

    // Controller
    polymorphic(WsAdminData::class, CMeasurementV1::class, CMeasurementV1.serializer())
    polymorphic(WsAdminData::class, CProcessSettingsV1::class, CProcessSettingsV1.serializer())
    polymorphic(WsAdminData::class, CClientSettingsV1::class, CClientSettingsV1.serializer())
    polymorphic(WsAdminData::class, CControllerSettingsV1::class, CControllerSettingsV1.serializer())
    polymorphic(WsAdminData::class, CAutoSettingsV1::class, CAutoSettingsV1.serializer())
    polymorphic(WsAdminData::class, CEventV1::class, CEventV1.serializer())
    polymorphic(WsAdminData::class, CStabilizationSettingsV1::class, CStabilizationSettingsV1.serializer())

    // Thermometers
    polymorphic(WsAdminData::class, TClientSettingsV1::class, TClientSettingsV1.serializer())
    polymorphic(WsAdminData::class, TMeasurementV1::class, TMeasurementV1.serializer())
    polymorphic(WsAdminData::class, ThermometersSettingsV1::class, ThermometersSettingsV1.serializer())
    polymorphic(WsAdminData::class, TStabilizationSettingsV1::class, TStabilizationSettingsV1.serializer())
}
val eventDataModule = SerializersModule {
    polymorphic(EventData::class, ProcessEventV1::class, ProcessEventV1.serializer())
    polymorphic(EventData::class, OffTempAlertEventV1::class, OffTempAlertEventV1.serializer())
}

@Model(ADMIN) // It is not model, but class must be annotated
val adminFormat = Json { serializersModule = webSocketDataModule + eventDataModule }
