package models.devices.controller.process

import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
enum class ProcessMode(val value: Int) {
    MANUAL(0),
    AUTO(1),
    ;

    companion object {
        fun create(value: Int) = when (value) {
            0 -> MANUAL
            1 -> AUTO
            else -> MANUAL
        }

        fun create(value: Boolean) = when (value) {
            false -> MANUAL
            true -> AUTO
        }
    }
}
