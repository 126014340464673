package admin.ui

import admin.services.auth.AuthService
import admin.services.auth.models.AuthEvents
import admin.services.system.BrowserService
import admin.services.websocket.WebSocketService
import admin.ui.AdminView.Companion.TAG
import extensions.collectCo
import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.Job
import logs.debugLog
import logs.errorLog
import navigator.observable.vm.ObservableValue
import tools.coAsync
import tools.tryLaunch

class AdminViewModel(
    private val authService: AuthService,
    private val webSocketService: WebSocketService,
    private val browserService: BrowserService,
) {
    private val isInternet = ObservableValue(true)
    val isUserLogged = ObservableValue(false)
    val currentView = ObservableValue("login")

    fun initializeAsync() = coAsync {
        val job: CompletableJob = Job()

        authService.events = AuthEvents(
            onLogin = { authToken ->
                debugLog(TAG, "On session login.")
                if (!job.isCancelled && !job.isCompleted) job.complete()
                webSocketService.start(authToken)
                isUserLogged.value = true
            },
            onLogout = {
                debugLog(TAG, "On session logout.")
                isUserLogged.value = false
                webSocketService.stop()
            },
            onReload = { },
        )

        browserService.state.collectCo { state ->
            isInternet.value = state.isOnline

            if (state.isOnline && state.isVisible) {
                debugLog(TAG, "STATE: Online && Visible")
                webSocketService.restart()
            }
        }

        return@coAsync try {
            authService.autoLogin()
            job.join()
            true
        } catch (e: Exception) {
            errorLog(TAG, e.toString())
            job.cancel()
            false
        }
    }

    fun logout() = tryLaunch({
        authService.logout()
    }, { error ->
        errorLog(TAG, error.toString())
    })
}
