package admin.models.system

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import websocket.WsAdminData
import websocket.WsDataCodes.SERVER_STATE_CPU_V1

@Model(ADMIN)
@Serializable
@SerialName(SERVER_STATE_CPU_V1)
data class Cpu(

    @SerialName("u")
    val usage: List<Double>,

    @SerialName("t")
    val temperature: Float?,

) : WsAdminData
