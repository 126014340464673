package models.devices.connection

import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN)
@Serializable
enum class ConnectionState(val color: String) {
    CONNECT("green"),
    DISCONNECT("red"),
    REFRESHING("orange"), // Additional state for controller during force pinging.
}
