package models.devices.controller.process

import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
enum class PowerState(val value: Int) {
    OK(0),
    ERROR(1),
    ;

    companion object {
        fun create(value: Int) = when (value) {
            0 -> OK
            1 -> ERROR
            else -> ERROR
        }
    }
}
