package models.devices.thermometers.settings.thermometers

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE
import rules.ModelDestination.THERMOMETERS

@Model(CORE, CLIENT, ADMIN, THERMOMETERS)
@Serializable
data class ThermometerV1(

    @SerialName("n")
    val name: String,

    @SerialName("c")
    val calibration: Float,
)
