package admin.models.system

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import websocket.WsAdminData
import websocket.WsDataCodes.SERVER_STATE_MEMORY_V1

@Model(ADMIN)
@Serializable
@SerialName(SERVER_STATE_MEMORY_V1)
data class Memory(

    /** Usage memory in kB */
    @SerialName("u")
    val usage: Int?,

    /** Usage swap memory in kB */
    @SerialName("su")
    val swapUsage: Int?,

) : WsAdminData
