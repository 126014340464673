package navigator.observable.vm

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancelChildren

abstract class ViewModel {

    val scope = CoroutineScope(Dispatchers.Main + Job())

    open fun destroy() {
        val entries: Array<*> = Any::class.js.asDynamic().entries(this) as Array<*>

        val observables = this::class.js.toString() // Observables created in "this" class
            .lines()
            .filter { it.contains("new ${Observable::class.simpleName}") }
            .map { it.split("=")[0].trim().substring(5) }

        entries
            .filter { observables.contains((it as Array<*>)[0].toString()) }
            .mapNotNull { (it as Array<*>)[1] as? Observable }
            .forEach { it.destroy() }

        scope.coroutineContext.cancelChildren()
    }
}
