package navigator.observable.vm

/** You must remember to clean manually listeners or use ViewModel class. */
class ObservableValue<T>(defaultValue: T? = null) : Observable {

    private val listeners = mutableListOf<(T?) -> Unit>()

    var value: T? = defaultValue
        set(value) {
            field = value
            listeners.forEach { it(value) }
        }

    fun onChanged(action: (T?) -> Unit) {
        listeners.add(action)
    }

    fun clean() {
        listeners.clear()
    }

    override fun destroy() {
        listeners.clear()
        value = null
    }
}
