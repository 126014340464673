package admin.ui.devices.connect

import admin.models.websocket.Ws2Server
import admin.models.websocket.WsAdminAction.AUTO_SETTINGS
import admin.models.websocket.WsAdminAction.CLIENT_SETTINGS
import admin.models.websocket.WsAdminAction.CONNECT_DEVICE
import admin.models.websocket.WsAdminAction.DEVICE_INFO
import admin.models.websocket.WsAdminAction.DEVICE_LOG
import admin.models.websocket.WsAdminAction.DEVICE_SETTINGS
import admin.models.websocket.WsAdminAction.DISCONNECT_DEVICE
import admin.models.websocket.WsAdminAction.EVENT
import admin.models.websocket.WsAdminAction.FACTORY_RESET
import admin.models.websocket.WsAdminAction.FETCH_DEVICE_DATA
import admin.models.websocket.WsAdminAction.MEASUREMENTS
import admin.models.websocket.WsAdminAction.PROCESS_RESET
import admin.models.websocket.WsAdminAction.PROCESS_SETTINGS
import admin.models.websocket.WsAdminAction.STABILIZATION_SETTINGS
import admin.services.auth.AuthService
import admin.services.websocket.WebSocketService
import exntensions.toJson
import extensions.collectCo
import extensions.getValue
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.cancel
import kotlinx.coroutines.delay
import models.devices.Device
import models.devices.DeviceType.CONTROLLER
import models.devices.DeviceType.THERMOMETERS
import models.devices.connection.ConnectionState
import models.devices.controller.event.EventV1
import models.devices.controller.logs.DeviceLogV1
import models.devices.controller.measurements.MeasurementV1
import models.devices.controller.measurements.TemperaturesV1
import models.devices.controller.process.ProcessSettingsV1
import models.devices.controller.settings.auto.AutoSettingsV1
import models.devices.controller.settings.client.ClientSettingsV1
import models.devices.controller.settings.controller.ControllerSettingsV1
import models.devices.controller.settings.stabilization.StabilizationSettingsV1
import models.devices.thermometers.settings.thermometers.ThermometersSettingsV1
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel
import tools.coAsync
import websocket.WsAdminData
import models.devices.thermometers.measurements.MeasurementV1 as TMeasurementV1
import models.devices.thermometers.settings.client.ClientSettingsV1 as TClientSettingsV1
import models.devices.thermometers.settings.stabilization.StabilizationSettingsV1 as TStabilizationSettingsV1

class DevicesConnectViewModel(
    private val authService: AuthService,
    private val webSocketService: WebSocketService,
) : ViewModel() {

    val connectState = ObservableValue<ConnectionState>()
    val connectSerialNumber = ObservableValue<String>()
    val autoscroll = ObservableValue(true)

    val device = ObservableValue<Device>()
    val measurementsHtml = ObservableValue<String>()
    val deviceSettingsHtml = ObservableValue<String>()
    val clientSettingsHtml = ObservableValue<String>()
    val stabilizationSettingsHtml = ObservableValue<String>()
    val processSettings = ObservableValue<ProcessSettingsV1>()
    val autoSettings = ObservableValue<AutoSettingsV1>()
    val lastEvent = ObservableValue<EventV1>()
    val lastControllerLog = ObservableValue<DeviceLogV1>()

    private var viewScope = CoroutineScope(Dispatchers.Main)

    fun initializeAsync() = coAsync {
        webSocketService.incoming.collectCo(scope = viewScope) {
            when (it.action) {
                CONNECT_DEVICE -> connectState.value = ConnectionState.CONNECT
                DISCONNECT_DEVICE -> connectState.value = ConnectionState.DISCONNECT
                DEVICE_SETTINGS -> deviceSettings(it.data)
                CLIENT_SETTINGS -> clientSettings(it.data)
                STABILIZATION_SETTINGS -> stabilizationSettings(it.data)
                MEASUREMENTS -> measurements(it.data)
                PROCESS_SETTINGS -> processSettings.value = it.data as ProcessSettingsV1?
                AUTO_SETTINGS -> autoSettings.value = it.data as AutoSettingsV1?
                EVENT -> lastEvent.value = it.data as EventV1?
                DEVICE_INFO -> deviceInfo(it.data as Device?)
                DEVICE_LOG -> lastControllerLog.value = it.data as DeviceLogV1?
                else -> {
                    console.log("${it.action} ${it.data?.toJson()}")
                }
            }
        }
    }

    private suspend fun deviceInfo(data: Device?) {
        device.value = data

        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("$TAG No auth token at dashboard initialization.")
            return
        }

        val serialNumber = connectSerialNumber.value ?: return
        val fetchWs2Server = Ws2Server(
            action = FETCH_DEVICE_DATA,
            authToken = authToken,
            serialNumber = serialNumber,
            deviceType = device.value?.type?.id ?: -1,
        )
        webSocketService.send(fetchWs2Server)
    }

    private fun measurements(data: WsAdminData?) {
        if (data == null) return
        measurementsHtml.value = when (device.value?.type) {
            CONTROLLER -> createControllerMeasurementsView(data as MeasurementV1)
            THERMOMETERS -> createThermometersMeasurementsView(data as TMeasurementV1)
            null -> return
        }
    }

    private fun stabilizationSettings(data: WsAdminData?) {
        if (data == null) return
        stabilizationSettingsHtml.value = when (device.value?.type) {
            CONTROLLER -> createControllerStabilizationSettingsView(data as StabilizationSettingsV1)
            THERMOMETERS -> createThermometersStabilizationSettingsView(data as TStabilizationSettingsV1)
            null -> return
        }
    }

    private fun clientSettings(data: WsAdminData?) {
        if (data == null) return
        clientSettingsHtml.value = when (device.value?.type) {
            CONTROLLER -> createControllerClientSettingsView(data as ClientSettingsV1)
            THERMOMETERS -> createThermometersClientSettingsView(data as TClientSettingsV1)
            null -> return
        }
    }

    private fun deviceSettings(data: WsAdminData?) {
        if (data == null) return
        deviceSettingsHtml.value = when (device.value?.type) {
            CONTROLLER -> createControllerSettingsView(data as ControllerSettingsV1)
            THERMOMETERS -> createThermometersSettingsView(data as ThermometersSettingsV1)
            null -> return
        }
    }

    override fun destroy() {
        super.destroy()
        viewScope.cancel()
    }

    suspend fun connect() {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("$TAG No auth token at dashboard initialization.")
            return
        }

        val serialNumber = connectSerialNumber.value ?: return
        val connectWs2Server = Ws2Server(
            action = CONNECT_DEVICE,
            authToken = authToken,
            serialNumber = serialNumber,
            deviceType = device.value?.type?.id ?: -1,
        )
        webSocketService.send(connectWs2Server)
    }

    suspend fun disconnect() {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("$TAG No auth token at dashboard initialization.")
            return
        }

        val serialNumber = connectSerialNumber.value ?: return
        val ws2Server = Ws2Server(
            action = DISCONNECT_DEVICE,
            authToken = authToken,
            serialNumber = serialNumber,
            deviceType = device.value?.type?.id ?: -1,
        )
        webSocketService.send(ws2Server)
        delay(50) // wait for disconnect
    }

    suspend fun processReset() {
        if (connectState.value == ConnectionState.DISCONNECT) return

        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("$TAG No auth token at dashboard initialization.")
            return
        }

        val serialNumber = connectSerialNumber.value ?: return
        val ws2Server = Ws2Server(
            action = PROCESS_RESET,
            authToken = authToken,
            serialNumber = serialNumber,
            deviceType = device.value?.type?.id ?: -1,
        )
        webSocketService.send(ws2Server)
    }

    suspend fun factoryReset() {
        if (connectState.value == ConnectionState.DISCONNECT) return

        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("$TAG No auth token at dashboard initialization.")
            return
        }

        val serialNumber = connectSerialNumber.value ?: return
        val ws2Server = Ws2Server(
            action = FACTORY_RESET,
            authToken = authToken,
            serialNumber = serialNumber,
            deviceType = device.value?.type?.id ?: -1,
        )
        webSocketService.send(ws2Server)
    }

    private fun createControllerSettingsView(controllerSettings: ControllerSettingsV1?): String {
        return """
          ControllerSettings:
          <table border="1">
            <tr>
              <th>Therm. 1</th>
              <th>Therm. 2</th>
              <th>Therm. 3</th>
              <th>Therm. 4</th>
              <th>Therm. 5</th>
              <th>Valve 1</th>
              <th>Valve 2</th>
              <th>Valve 3</th>
              <th>Valve 4</th>
              <th>Valve 5</th>
              <th>Valve 6</th>
              <th>ElectricValve 1</th>
              <th>ElectricValve 2</th>
              <th>PrecisionValve 1</th>
              <th>PrecisionValve 2</th>
              <th>PrecisionValve 3</th>
            </tr>
            <tr>
                <td>${controllerSettings?.thermometer1?.name} ${controllerSettings?.thermometer1?.offTemperature}</td>
                <td>${controllerSettings?.thermometer2?.name} ${controllerSettings?.thermometer2?.offTemperature}</td>
                <td>${controllerSettings?.thermometer3?.name} ${controllerSettings?.thermometer3?.offTemperature}</td>
                <td>${controllerSettings?.thermometer4?.name} ${controllerSettings?.thermometer4?.offTemperature}</td>
                <td>${controllerSettings?.thermometer5?.name} ${controllerSettings?.thermometer5?.offTemperature}</td>
                <td>${controllerSettings?.valve1?.name}</td>
                <td>${controllerSettings?.valve2?.name}</td>
                <td>${controllerSettings?.valve3?.name}</td>
                <td>${controllerSettings?.valve4?.name}</td>
                <td>${controllerSettings?.valve5?.name}</td>
                <td>${controllerSettings?.valve6?.name}</td>
                <td>${controllerSettings?.electricValve1?.name}</td>
                <td>${controllerSettings?.electricValve2?.name}</td>
                <td>${controllerSettings?.precisionValve1?.name}</td>
                <td>${controllerSettings?.precisionValve2?.name}</td>
                <td>${controllerSettings?.precisionValve3?.name}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createThermometersSettingsView(thermometersSettingsV1: ThermometersSettingsV1?): String {
        return """
          ThermometersSettings:
          <table border="1">
            <tr>
              <th>Therm. 1</th>
              <th>Therm. 2</th>
              <th>Therm. 3</th>
              <th>Therm. 4</th>
              <th>Therm. 5</th>
            </tr>
            <tr>
                <td>${thermometersSettingsV1?.thermometer1?.name} ${thermometersSettingsV1?.thermometer1?.calibration}</td>
                <td>${thermometersSettingsV1?.thermometer2?.name} ${thermometersSettingsV1?.thermometer2?.calibration}</td>
                <td>${thermometersSettingsV1?.thermometer3?.name} ${thermometersSettingsV1?.thermometer3?.calibration}</td>
                <td>${thermometersSettingsV1?.thermometer4?.name} ${thermometersSettingsV1?.thermometer4?.calibration}</td>
                <td>${thermometersSettingsV1?.thermometer5?.name} ${thermometersSettingsV1?.thermometer5?.calibration}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createControllerClientSettingsView(clientSettings: ClientSettingsV1): String {
        return """
          ClientSettings:
          <table border="1">
            <tr>
              <th>Thermometer 1</th>
              <th>Thermometer 2</th>
              <th>Thermometer 3</th>
              <th>Thermometer 4</th>
              <th>Thermometer 5</th>
              <th>Valve 1</th>
              <th>Valve 2</th>
              <th>Valve 3</th>
              <th>Valve 4</th>
              <th>Valve 5</th>
              <th>Valve 6</th>
              <th>ElectricValve 1</th>
              <th>ElectricValve 2</th>
              <th>PrecisionValve 1</th>
              <th>PrecisionValve 2</th>
              <th>PrecisionValve 3</th>
              <th>head</th>
              <th>buffer</th>
              <th>tank</th>
            </tr>
            <tr>
                <td>${clientSettings.thermometer1.isVisible}</td>
                <td>${clientSettings.thermometer2.isVisible}</td>
                <td>${clientSettings.thermometer3.isVisible}</td>
                <td>${clientSettings.thermometer4.isVisible}</td>
                <td>${clientSettings.thermometer5.isVisible}</td>
                <td>${clientSettings.valve1.isVisible}</td>
                <td>${clientSettings.valve2.isVisible}</td>
                <td>${clientSettings.valve3.isVisible}</td>
                <td>${clientSettings.valve4.isVisible}</td>
                <td>${clientSettings.valve5.isVisible}</td>
                <td>${clientSettings.valve6.isVisible}</td>
                <td>${clientSettings.electricValve1.isVisible}</td>
                <td>${clientSettings.electricValve2.isVisible}</td>
                <td>${clientSettings.precisionValve1.isVisible} ${clientSettings.precisionValve1.favourites}</td>
                <td>${clientSettings.precisionValve2.isVisible} ${clientSettings.precisionValve2.favourites}</td>
                <td>${clientSettings.precisionValve3.isVisible} ${clientSettings.precisionValve3.favourites}</td>
                 <td>${clientSettings.advance.headThermometerPosition}</td>
                 <td>${clientSettings.advance.bufferThermometerPosition}</td>
                 <td>${clientSettings.advance.tankThermometerPosition}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createThermometersClientSettingsView(clientSettings: TClientSettingsV1): String {
        return """
          ClientSettings:
          <table border="1">
            <tr>
              <th>Thermometer 1</th>
              <th>Thermometer 2</th>
              <th>Thermometer 3</th>
              <th>Thermometer 4</th>
              <th>Thermometer 5</th>
              <th>head</th>
              <th>buffer</th>
              <th>tank</th>
            </tr>
            <tr>
                <td>${clientSettings.thermometer1.isVisible}</td>
                <td>${clientSettings.thermometer2.isVisible}</td>
                <td>${clientSettings.thermometer3.isVisible}</td>
                <td>${clientSettings.thermometer4.isVisible}</td>
                <td>${clientSettings.thermometer5.isVisible}</td>
                 <td>${clientSettings.advance.headThermometerPosition}</td>
                 <td>${clientSettings.advance.bufferThermometerPosition}</td>
                 <td>${clientSettings.advance.tankThermometerPosition}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createThermometersStabilizationSettingsView(stabilizationSettings: TStabilizationSettingsV1): String? {
        return """
          StabilizationSettings:
          <table border="1">
            <tr>
              <th>T1</th>
              <th>T2</th>
              <th>T3</th>
              <th>T4</th>
              <th>T5</th>
            </tr>
            <tr>
              <td>${stabilizationSettings.temperature1}</td>
              <td>${stabilizationSettings.temperature2}</td>
              <td>${stabilizationSettings.temperature3}</td>
              <td>${stabilizationSettings.temperature4}</td>
              <td>${stabilizationSettings.temperature5}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createControllerStabilizationSettingsView(stabilizationSettings: StabilizationSettingsV1): String? {
        return """
          StabilizationSettings:
          <table border="1">
            <tr>
              <th>T1</th>
              <th>T2</th>
              <th>T3</th>
              <th>T4</th>
              <th>T5</th>
            </tr>
            <tr>
              <td>${stabilizationSettings.temperature1}</td>
              <td>${stabilizationSettings.temperature2}</td>
              <td>${stabilizationSettings.temperature3}</td>
              <td>${stabilizationSettings.temperature4}</td>
              <td>${stabilizationSettings.temperature5}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createThermometersMeasurementsView(measurements: models.devices.thermometers.measurements.MeasurementV1): String? {
        return """
          Measurements:
          <table border="1">
            <tr>
              <th>${TemperaturesV1::temperature1.name}</th>
              <th>${TemperaturesV1::temperature2.name}</th>
              <th>${TemperaturesV1::temperature3.name}</th>
              <th>${TemperaturesV1::temperature4.name}</th>
              <th>${TemperaturesV1::temperature5.name}</th>
            </tr>
            <tr>
                <td>${measurements.temperatures.temperature1}</td>
                <td>${measurements.temperatures.temperature2}</td>
                <td>${measurements.temperatures.temperature3}</td>
                <td>${measurements.temperatures.temperature4}</td>
                <td>${measurements.temperatures.temperature5}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createControllerMeasurementsView(measurements: MeasurementV1): String {
        return """
          Measurements:
          <table border="1">
            <tr>
              <th>${TemperaturesV1::temperature1.name}</th>
              <th>${TemperaturesV1::temperature2.name}</th>
              <th>${TemperaturesV1::temperature3.name}</th>
              <th>${TemperaturesV1::temperature4.name}</th>
              <th>${TemperaturesV1::temperature5.name}</th>
              <th>${MeasurementV1::isFloodDetected.name}</th>
              <th>${MeasurementV1::watt.name}</th>
            </tr>
            <tr>
                <td>${measurements.temperatures.temperature1}</td>
                <td>${measurements.temperatures.temperature2}</td>
                <td>${measurements.temperatures.temperature3}</td>
                <td>${measurements.temperatures.temperature4}</td>
                <td>${measurements.temperatures.temperature5}</td>
                <td>${measurements.isFloodDetected}</td>
                <td>${measurements.watt}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    companion object {
        private const val TAG = "[CONNECT]"
    }
}
