package admin.ui.dashboard

import admin.models.system.Cpu
import admin.models.system.HardDriveInfo
import admin.models.system.Memory
import admin.models.system.MemoryInfo
import admin.models.system.Network
import admin.models.websocket.Ws2Server
import admin.models.websocket.WsAdminAction.CONNECT_SERVER_STATE
import admin.models.websocket.WsAdminAction.DISCONNECT_SERVER_STATE
import admin.models.websocket.WsAdminAction.SERVER_STATE_CPU
import admin.models.websocket.WsAdminAction.SERVER_STATE_MEMORY
import admin.models.websocket.WsAdminAction.SERVER_STATE_NETWORK
import admin.services.SystemApiService
import admin.services.auth.AuthService
import admin.services.system.BrowserService
import admin.services.websocket.WebSocketService
import extensions.collectCo
import extensions.getValue
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.cancel
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel
import tools.co
import tools.coAsync
import tools.tickerFlow
import kotlin.time.Duration.Companion.minutes

class DashboardViewModel(
    private val authService: AuthService,
    private val browserService: BrowserService,
    private val systemApiService: SystemApiService,
    private val webSocketService: WebSocketService,
) : ViewModel() {

    val memory = ObservableValue<Memory>()
    val memoryInfo = ObservableValue<MemoryInfo>()
    val hardDriveInfo = ObservableValue<HardDriveInfo>()
    val network = ObservableValue<Network>()
    val cpu = ObservableValue<Cpu>()

    private var viewScope = CoroutineScope(Dispatchers.Main)

    fun initializeAsync() = coAsync {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("$TAG No auth token at dashboard initialization.")
            return@coAsync
        }

        co(scope = viewScope) {
            tickerFlow(1.minutes)
                .onEach {
                    memoryInfo.value = systemApiService.getMemoryInfoAsync(authToken).await()
                    hardDriveInfo.value = systemApiService.getHardDriveInfoAsync(authToken).await()
                }
                .collect()
        }

        webSocketService.incoming.collectCo(scope = viewScope) {
            when (it.action) {
                SERVER_STATE_CPU -> cpu.value = it.data as? Cpu
                SERVER_STATE_MEMORY -> memory.value = it.data as? Memory
                SERVER_STATE_NETWORK -> network.value = it.data as? Network
                else -> {}
            }
        }

        delay(1000) // wait until ws initialize
        webSocketService.send(Ws2Server(CONNECT_SERVER_STATE, authToken))
    }

    override fun destroy() {
        super.destroy()
        viewScope.cancel()
    }

    suspend fun disconnectServerState() {
        val authToken = authService.tokenFlow.getValue() ?: run {
            console.log("$TAG No auth token at dashboard initialization.")
            return
        }

        webSocketService.send(Ws2Server(DISCONNECT_SERVER_STATE, authToken))
        delay(50) // wait for disconnect
    }

    companion object {
        private const val TAG = "[DASHBOARD]"
    }
}
