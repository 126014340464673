package admin.services

import admin.services.api.ApiServiceImpl
import admin.services.auth.AuthService
import admin.services.auth.AuthServiceImpl
import admin.services.cookie.CookieService
import admin.services.system.BrowserService
import admin.services.system.BrowserServiceImpl
import admin.services.updates.UpdatesService
import admin.services.updates.UpdatesServiceImpl
import admin.services.websocket.WebSocketService
import admin.services.websocket.WebSocketServiceImpl

class Services {

    val cookie = CookieService()
    val api = ApiServiceImpl()
    val browser: BrowserService = BrowserServiceImpl()

    val auth: AuthService = AuthServiceImpl(api.session, cookie)
    val updates: UpdatesService = UpdatesServiceImpl(auth, api.updates)
    val webSocket: WebSocketService = WebSocketServiceImpl()
}
