package models.devices

import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN)
enum class DeviceType(val id: Byte) {
    CONTROLLER(0),
    THERMOMETERS(1),
    ;

    companion object {
        fun create(byte: Byte): DeviceType {
            return when (byte) {
                CONTROLLER.id -> CONTROLLER
                THERMOMETERS.id -> THERMOMETERS
                else -> throw Exception("Not recognized DeviceType id: $byte")
            }
        }
    }
}
