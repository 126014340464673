package models.devices.controller.process

import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
enum class PrecisionValveState(val value: Int) {
    UNPLUGGED(0),
    IDLE(1),
    WORKING(2),
    ERROR(3),
    ;

    companion object {
        fun create(value: Int) = when (value) {
            0 -> UNPLUGGED
            1 -> IDLE
            2 -> WORKING
            3 -> ERROR
            else -> ERROR
        }
    }
}
