import admin.services.Services
import admin.ui.AdminView
import kotlinx.browser.window
import navigator.Navigator

fun main() {
    val services = Services()
    val navigator = Navigator(rootUrlBit = "login", urlBitsAvailableForAuthorized = listOf("dashboard", "devices", "users"))
    val mainView = AdminView(navigator, services)

    window.onload = {
        navigator.initialize(mainView)
    }
}
