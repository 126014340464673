package admin.ui.dashboard

import admin.services.SystemApiService
import admin.services.auth.AuthService
import admin.services.system.BrowserService
import admin.services.websocket.WebSocketService
import exntensions.format
import logs.debugLog
import navigator.html.StaticHtml
import navigator.observable.view.ObservableElement
import navigator.view.LoadResult
import navigator.view.View
import org.w3c.dom.HTMLSpanElement

class DashboardView(
    authService: AuthService,
    browserService: BrowserService,
    systemApiService: SystemApiService,
    webSocketService: WebSocketService,
) : StaticHtml(constUrlBit = "dashboard", resourceUrl = "/adminBody/dashboard/dashboard.html"), View {

    private val viewModel = DashboardViewModel(
        browserService = browserService,
        webSocketService = webSocketService,
        systemApiService = systemApiService,
        authService = authService,
    )

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")

        viewModel.initializeAsync().await()

        bindElements()

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        viewModel.disconnectServerState()
        viewModel.destroy()
    }

    private fun bindElements() {
        ObservableElement<HTMLSpanElement>("cpu-usage-1-span")
            .bindObservable(viewModel.cpu) { cpu, element ->
                if (cpu?.usage.isNullOrEmpty()) return@bindObservable
                element.innerHTML = cpu?.usage?.get(0)?.format(2).toString()
            }

        ObservableElement<HTMLSpanElement>("hard-drive-total-span")
            .bindObservable(viewModel.hardDriveInfo) { hardDriveInfo, element ->
                element.innerHTML = convertKbToGb(hardDriveInfo?.total).toString()
            }

        ObservableElement<HTMLSpanElement>("hard-drive-usage-span")
            .bindObservable(viewModel.hardDriveInfo) { hardDriveInfo, element ->
                element.innerHTML = convertKbToGb(hardDriveInfo?.usage).toString()
            }

        ObservableElement<HTMLSpanElement>("hard-drive-logs-span")
            .bindObservable(viewModel.hardDriveInfo) { hardDriveInfo, element ->
                element.innerHTML = convertKbToGb(hardDriveInfo?.logs).toString()
            }

        ObservableElement<HTMLSpanElement>("memory-total-span")
            .bindObservable(viewModel.memoryInfo) { memoryInfo, element ->
                element.innerHTML = convertKbToGb(memoryInfo?.total).toString()
            }

        ObservableElement<HTMLSpanElement>("memory-swap-total-span")
            .bindObservable(viewModel.memoryInfo) { memoryInfo, element ->
                element.innerHTML = convertKbToGb(memoryInfo?.swapTotal).toString()
            }

        ObservableElement<HTMLSpanElement>("memory-usage-span")
            .bindObservable(viewModel.memory) { memory, element ->
                val usage = memory?.usage ?: return@bindObservable
                val total = viewModel.memoryInfo.value?.total

                element.innerHTML = if (total != null) {
                    "${convertKbToGb(usage)} (${(usage.toFloat() / total.toFloat() * 100).format(2)}%)"
                } else {
                    convertKbToGb(usage).toString()
                }
            }

        ObservableElement<HTMLSpanElement>("memory-swap-span")
            .bindObservable(viewModel.memory) { memory, element ->
                val swapUsage = memory?.swapUsage ?: return@bindObservable
                val swapTotal = viewModel.memoryInfo.value?.swapTotal

                element.innerHTML = if (swapTotal != null) {
                    "${convertKbToGb(swapUsage)} (${(swapUsage.toFloat() / swapTotal.toFloat() * 100).format(2)}%)"
                } else {
                    convertKbToGb(swapUsage).toString()
                }
            }

        ObservableElement<HTMLSpanElement>("network-download-span")
            .bindObservable(viewModel.network) { network, element ->
                element.innerHTML = convertBToMb(network?.download).toString()
            }

        ObservableElement<HTMLSpanElement>("network-upload-span")
            .bindObservable(viewModel.network) { network, element ->
                element.innerHTML = convertBToMb(network?.upload).toString()
            }
    }

    private fun convertKbToGb(value: Long?): Float? {
        return if (value != null) value.toFloat() / 1000000f else null
    }

    private fun convertKbToGb(value: Int?): Float? {
        return if (value != null) value.toFloat() / 1000000f else null
    }

    private fun convertBToMb(value: Long?): Float? {
        return if (value != null) value.toFloat() / 1000000f else null
    }

    companion object {
        const val TAG = "[DASHBOARD]"
    }
}
