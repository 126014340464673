package admin.services.api.models

import org.w3c.files.File

data class UpdateRequest(
    val version: String,
    val file: File,
    val device: String,
    val model: String,
    val changelog: List<String>,
    val measurementVersion: Int,
    val processVersion: Int,
    val settingsVersion: Int,
)
