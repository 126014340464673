package admin.ui.users.manager

import admin.services.api.AccessApiService
import admin.services.api.AccountsApiService
import admin.services.auth.AuthService
import admin.ui.users.manager.windows.add.UserAddWindowView
import admin.ui.users.manager.windows.edit.UserEditWindowView
import kotlinx.browser.document
import logs.debugLog
import models.account.Account
import navigator.html.StaticHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableInput
import navigator.view.LoadResult
import navigator.view.View
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTableElement
import tools.co
import tools.getElementById

class UsersManagerView(
    private val authService: AuthService,
    private val accountsApiService: AccountsApiService,
    private val accessApiService: AccessApiService,
) : StaticHtml(constUrlBit = "manager", resourceUrl = "/adminBody/users/manager/manager.html"), View {

    private val viewModel: UsersManagerViewModel = UsersManagerViewModel(authService, accountsApiService)

    private val accountsTable get() = getElementById<HTMLTableElement>("user-manager-accounts-table")
    private val accountsItems: MutableList<AccountRowView> = mutableListOf()

    private val windowMountPoint get() = document.getElementById("window-mount-point")?.unsafeCast<HTMLElement>()
    private val addWindow: UserAddWindowView? = null
    private val editWindow: UserEditWindowView? = null

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")
        accountsTable?.innerHTML = createView()

        viewModel.accounts.onChanged {
            accountsTable?.innerHTML = createView()
            accountsItems.forEach { it.onLoad() }
        }

        bindElements()

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        viewModel.destroy()
    }

    private fun bindElements() {
        ObservableInput<HTMLInputElement>("user-manager-search-input")
            .bindObservable(viewModel.searchValue) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.searchValue.value = value }

        ObservableButton<HTMLButtonElement>("user-manager-search-button")
            .onClickCo { viewModel.refresh() }

        ObservableButton<HTMLButtonElement>("user-manager-add-button")
            .onClickCo { addAccountWindow() }
    }

    private fun createView(): String {
        accountsItems.clear()
        accountsItems += viewModel.accounts
            .map { AccountRowView(it, ::editAccountWindow) }
        return createTableHeaderView() + accountsItems.joinToString("") { it.createView() }
    }

    private fun createTableHeaderView(): String {
        return """
            <tr>
                <th class="th"></th>
                <th class="th">Uuid</th>
                <th class="th">Login</th>
                <th class="th">Password</th>
                <th class="th">Email</th>
                <th class="th"></th>
            </tr>
        """.trimIndent()
    }

    private suspend fun editAccountWindow(account: Account) {
        if (!windowMountPoint?.innerHTML.isNullOrEmpty()) return

        val editWindow = UserEditWindowView(
            authService = authService,
            accountsApiService = accountsApiService,
            accessApiService = accessApiService,
            account = account,
            onCancelClicked = {
                windowMountPoint?.innerHTML = ""
                editWindow?.destroy()
            },
            onSaveClicked = {
                co { viewModel.refresh() }
                windowMountPoint?.innerHTML = ""
                editWindow?.destroy()
            },
            onDeleteClicked = {
                co { viewModel.refresh() }
                windowMountPoint?.innerHTML = ""
                editWindow?.destroy()
            },
        )

        windowMountPoint?.innerHTML = editWindow.createView()
        editWindow.load()
    }

    private suspend fun addAccountWindow() {
        if (!windowMountPoint?.innerHTML.isNullOrEmpty()) return

        val addWindow = UserAddWindowView(
            authService = authService,
            accountsApiService = accountsApiService,
            onCancelClicked = {
                windowMountPoint?.innerHTML = ""
                addWindow?.destroy()
            },
            onSaveClicked = {
                co { viewModel.refresh() }
                windowMountPoint?.innerHTML = ""
                addWindow?.destroy()
            },
        )

        windowMountPoint?.innerHTML = addWindow.createView()
        addWindow.load()
    }

    class AccountRowView(
        private val account: Account,
        val editAccount: suspend (Account) -> Unit,
    ) {
        private val editButtonViewId = "user-manager-edit-btn-${account.uuid}"

        fun onLoad() {
            ObservableButton<HTMLDivElement>(editButtonViewId)
                .onClickCo { editAccount(account) }
        }

        fun createView(): String {
            return """
                <tr>
                  <td class="td"></td>
                  <td class="td">${account.uuid}</td>
                  <td class="td">${account.login}</td>
                  <td class="td">${account.password}</td>
                  <td class="td">${account.email}</td>
                  <td class="td">
                      <div id="$editButtonViewId" style="float: left;" class="btn-white from-left small-btn">Edytuj</div>
                  </td>
                </tr>
            """.trimIndent()
        }
    }

    companion object {
        const val TAG = "[MANAGER]"
    }
}
