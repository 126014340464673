package admin.models.websocket

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import websocket.WsAdminData

@Model(ADMIN)
@Serializable
data class Ws2Admin(

    @SerialName("a")
    val action: WsAdminAction,

    @SerialName("s")
    val serialNumber: String,

    @SerialName("c")
    val code: Int,

    @SerialName("d")
    val data: WsAdminData? = null,

    @SerialName("x")
    val additionalField: String = "",
)
