package models.devices.controller.event.alert

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import models.devices.controller.event.EventData
import models.devices.controller.event.EventData.Companion.OFF_TEMP_ALERT_V1
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
@SerialName(OFF_TEMP_ALERT_V1)
data class OffTempAlertEventV1(

    @SerialName("t1")
    val thermometer1: Boolean,

    @SerialName("t2")
    val thermometer2: Boolean,

    @SerialName("t3")
    val thermometer3: Boolean,

    @SerialName("t4")
    val thermometer4: Boolean,

    @SerialName("t5")
    val thermometer5: Boolean,

) : EventData() {

    fun getLogText(): String {
        return "OffTempAlertEventV1(t1=$thermometer1, t2=$thermometer2, t3=$thermometer3, t4=$thermometer4, t5=$thermometer5)"
    }
}
