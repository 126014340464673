package admin.ui.devices

import admin.services.Services
import admin.ui.devices.connect.DevicesConnectView
import admin.ui.devices.manager.DevicesManagerView
import admin.ui.devices.updates.DevicesUpdatesView
import admin.ui.users.UsersView
import exntensions.boldFont
import exntensions.normalFont
import kotlinx.browser.document
import logs.debugLog
import navigator.Navigator
import navigator.html.StaticHtml
import navigator.observable.view.ObservableButton
import navigator.view.LoadResult
import navigator.view.View
import navigator.view.ViewNode
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLUListElement

class DevicesView(
    override val navigator: Navigator,
    val services: Services,
) : StaticHtml(constUrlBit = "devices", resourceUrl = "/adminBody/devices/devices.html"), ViewNode {

    override val urlBitIndex: Int = 1
    override val contentViewId: String = "devices-content"
    override var htmlView: StaticHtml? = null

    private val viewModel = DevicesViewModel()

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")

        addressUrl.getOrNull(urlBitIndex)?.let { viewModel.initialize(it) }

        bindElements()

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
        addressUrl.getOrNull(urlBitIndex)?.let { viewModel.reloadView(it) }
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        (htmlView as? View)?.onDestroy()
    }

    override suspend fun navigateTo(addressUrl: List<String>) {
        debugLog(TAG, "navigate($addressUrl)")

        if (addressUrl.size > 1) {
            val urlBit = addressUrl[urlBitIndex]
            debugLog(UsersView.TAG, "navigate($urlBit)")
            run(addressUrl, urlBit)
        }
    }

    override suspend fun buildView(addressUrl: List<String>, urlBit: String) {
        debugLog(TAG, "View building: $urlBit")

        htmlView = when (urlBit) {
            "manager" -> DevicesManagerView(services.auth, services.api.device)
            "updates" -> DevicesUpdatesView(services.updates)
            "connect" -> DevicesConnectView(services.auth, services.webSocket)
            else -> throw Exception("Invalid path for $urlBit at layer 1.")
        }

        document.getElementById(contentViewId)
            ?.unsafeCast<HTMLElement>()
            ?.innerHTML = htmlView?.getView()!!

        navigator.updateUrlHistory(addressUrl, replace = true)
    }

    private fun bindElements() {
        ObservableButton<HTMLUListElement>("connect-button")
            .bindObservable(viewModel.currentView) { value, element ->
                if (value == "connect") element.boldFont() else element.normalFont()
            }
            .onClickCo {
                viewModel.currentView.value = "connect"
                navigator.navigate(listOf("devices", "connect"))
            }

        ObservableButton<HTMLUListElement>("manager-button")
            .bindObservable(viewModel.currentView) { value, element ->
                if (value == "manager") element.boldFont() else element.normalFont()
            }
            .onClickCo {
                viewModel.currentView.value = "manager"
                navigator.navigate(listOf("devices", "manager"))
            }

        ObservableButton<HTMLUListElement>("updates-button")
            .bindObservable(viewModel.currentView) { value, element ->
                if (value == "updates") element.boldFont() else element.normalFont()
            }
            .onClickCo {
                viewModel.currentView.value = "updates"
                navigator.navigate(listOf("devices", "updates"))
            }
    }

    companion object {
        const val TAG = "[DEVICES]"
    }
}
