package admin.ui.devices.updates.windows.add

import admin.services.api.models.UpdateRequest
import admin.services.updates.UpdatesService
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel
import org.w3c.files.File
import tools.VersionTools

class DeviceUpdateAddWindowViewModel(
    initVersion: String,
    private val device: String,
    private val model: String,
    private val updatesService: UpdatesService,
) : ViewModel() {

    val errorMessage = ObservableValue<String>()
    val version = ObservableValue(initVersion)
    val changelog = ObservableValue("")
    val updateFile = ObservableValue<File>()

    suspend fun save(): Boolean {
        if (!validate()) return false

        val update = UpdateRequest(
            version = version.value ?: return false,
            file = updateFile.value ?: return false,
            device = device,
            model = model,
            changelog = changelog.value?.split("\n")?.toList() ?: return false,
            measurementVersion = 1,
            processVersion = 1,
            settingsVersion = 1,
        )

        return try {
            updatesService.newUpdateAsync(update).await()
            true
        } catch (t: Throwable) {
            errorMessage.value = t.message
            false
        }
    }

    private fun validate(): Boolean {
        return try {
            version.value?.let { VersionTools.parse(it) }
            if (updateFile.value == null) throw Throwable("No update file")
            true
        } catch (t: Throwable) {
            errorMessage.value = t.message ?: "Validate error"
            false
        }
    }
}
