package models.api

import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN)
sealed class ApiError(open val code: Short, override val message: String?) : Exception()

class BadRequest : ApiError(400, "Http error: BadRequest")

class Unauthorized : ApiError(401, "Http error: Unauthorized")

class Forbidden : ApiError(403, "Http error: Forbidden")

class NotFound : ApiError(404, "Http error: NotFound")

class MethodNotAllowed : ApiError(405, "Http error: MethodNotAllowed")

class InternalServerError : ApiError(500, "Http error: InternalServerError")

class OtherApiError(override val code: Short) : ApiError(code, "Http error: OtherError")

class OtherError(override val message: String?) : ApiError(-1, "Http error: OtherError")
