package models.devices.thermometers.settings.stabilization

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsDataCodes

@Model(ModelDestination.CORE, ModelDestination.CLIENT, ModelDestination.ADMIN)
@Serializable
@SerialName(WsDataCodes.THERMOMETERS_STABILIZATION_SETTINGS_V1)
data class StabilizationSettingsV1(

    @SerialName("t1")
    val temperature1: Float,

    @SerialName("t2")
    val temperature2: Float,

    @SerialName("t3")
    val temperature3: Float,

    @SerialName("t4")
    val temperature4: Float,

    @SerialName("t5")
    val temperature5: Float,

) : WsClientData, WsAdminData
