package models.access

import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN)
@Serializable
data class Access(
    val uuid: String,
    val type: Byte,
    val accountUuid: String,
    val name: String,
    val isFavourite: Boolean,
    val position: Byte,
    val device: AccessDevice,
)
