package admin.models.system

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN

@Model(ADMIN)
@Serializable
data class MemoryInfo(

    /** Total memory in kB */
    @SerialName("t")
    val total: Int?,

    /** Total swap memory in kB */
    @SerialName("st")
    val swapTotal: Int?,
)
