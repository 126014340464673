package admin.ui.users.manager.windows.add

import admin.services.api.AccountsApiService
import admin.services.auth.AuthService
import kotlinx.browser.window
import kotlinx.coroutines.await
import logs.infoLog
import navigator.html.DynamicHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableInput
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import tools.co

class UserAddWindowView(
    private val authService: AuthService,
    private val accountsApiService: AccountsApiService,
    private val onSaveClicked: () -> Unit,
    private val onCancelClicked: () -> Unit,
) : DynamicHtml() {

    private lateinit var view: String
    private lateinit var viewModel: UserAddWindowViewModel

    suspend fun createView(): String {
        view = window
            .fetch("${window.location.origin}$WINDOW_HTML_PATH")
            .then { it.text() }.await().toString()

        viewModel = UserAddWindowViewModel(authService, accountsApiService)

        return view
    }

    fun load() {
        ObservableInput<HTMLInputElement>("user-manager-add-login-name")
            .bindObservable(viewModel.login) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.login.value = value }

        ObservableInput<HTMLInputElement>("user-manager-add-password-name")
            .bindObservable(viewModel.password) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.password.value = value }

        ObservableInput<HTMLInputElement>("user-manager-add-email-name")
            .bindObservable(viewModel.email) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.email.value = value }

        ObservableButton<HTMLDivElement>("user-manager-add-save-button")
            .onClick {
                infoLog(TAG, "Clicked Save")
                co { if (viewModel.save()) onSaveClicked() }
            }

        ObservableButton<HTMLDivElement>("user-manager-add-cancel-button")
            .onClick {
                infoLog(TAG, "Clicked Cancel")
                onCancelClicked()
            }
    }

    fun destroy() {
        viewModel.destroy()
    }

    companion object {
        private const val TAG = "[NEW_ACCOUNT_WINDOW]"
        private const val WINDOW_HTML_PATH = "/adminBody/users/manager/manager_add_window.html"
    }
}
