package tools

import models.errors.ParseFailed
import models.update.Update
import models.update.UpdateVersion

class VersionTools {

    companion object {

        fun parse(string: String): UpdateVersion {
            try {
                val version = string.split(".")
                return UpdateVersion(
                    major = version[0].toInt(),
                    minor = version[1].toInt(),
                    patch = version[2].toInt(),
                )
            } catch (e: Exception) {
                throw ParseFailed("Wrong format of update version.")
            }
        }

        fun getHighestUpdate(list: List<Update>): Update {
            val major = list.maxOf { it.version.major }
            val minor = list.maxOf { it.version.minor }
            val path = list.maxOf { it.version.patch }

            return list
                .filter { it.version.major == major }
                .filter { it.version.minor == minor }
                .firstOrNull { it.version.patch == path }
                ?: throw NoSuchElementException()
        }
    }
}
