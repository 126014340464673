package admin.services.system

import admin.services.system.models.BrowserState
import exntensions.isOnline
import exntensions.onOnlineChange
import exntensions.onVisibilityChange
import extensions.getValue
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import tools.bufferSharedFlow
import tools.co

class BrowserServiceImpl : BrowserService {

    override val state: MutableSharedFlow<BrowserState> = bufferSharedFlow()

    override fun start() {
        state.tryEmit(BrowserState(window.isOnline(), true)) // default

        observeVisibility()
        observeOnline()
    }

    private fun observeVisibility() {
        window.document.onVisibilityChange { isVisible ->
            state.tryEmit(BrowserState(window.isOnline(), isVisible))
        }
    }

    private fun observeOnline() {
        window.onOnlineChange { isOnline ->
            co {
                onlineSyncHack()
                val oldState = state.getValue() ?: return@co
                state.tryEmit(oldState.copy(isOnline = isOnline))
            }
        }
    }

    /** BUG: Browser cannot synchronize online state immediate. Need 200 ms more... */
    private suspend fun onlineSyncHack() = delay(200)
}
