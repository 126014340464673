package models.update

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE

@Model(CORE, ADMIN, CONTROLLER)
@Serializable
data class UpdateVersion(

    @SerialName("ma")
    val major: Int,

    @SerialName("mi")
    val minor: Int,

    @SerialName("p")
    val patch: Int,
) {
    fun name() = "$major.$minor.$patch"
}
