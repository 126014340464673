package admin.ui.login

import admin.services.auth.AuthService
import admin.services.cookie.CookieService
import admin.services.cookie.CookieService.Companion.COOKIES_PERMISSION
import admin.ui.login.LoginView.Companion.TAG
import logs.errorLog
import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel
import tools.tryLaunch

class LoginViewModel(
    private val authService: AuthService,
    private val cookieService: CookieService,
) : ViewModel() {

    val user = ObservableValue<String>()
    val password = ObservableValue<String>()
    val isPasswordVisible = ObservableValue(false)
    val isLoginError = ObservableValue(false)

    fun login() = tryLaunch({
        val serialNumber = user.value ?: throw Throwable("Empty serial number")
        val password = password.value ?: throw Throwable("Empty password")

        authService.login(serialNumber, password)
    }, { error ->
        isLoginError.value = true
        errorLog(TAG, error.toString())
    })

    fun acceptCookies() {
        cookieService[COOKIES_PERMISSION] = "true"
    }

    fun hasPermissionToCookies(): Boolean = cookieService[COOKIES_PERMISSION] == "true"
}
