package models.devices.controller.logs

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE
import rules.ModelDestination.THERMOMETERS
import websocket.WsAdminData
import websocket.WsControllerData
import websocket.WsDataCodes.DEVICE_LOG
import websocket.WsThermometersData

@Model(CORE, ADMIN, CONTROLLER, THERMOMETERS)
@Serializable
@SerialName(DEVICE_LOG)
data class DeviceLogV1(

    @SerialName("ti")
    val time: Long?,

    @SerialName("ta")
    val tag: String?,

    @SerialName("m")
    val message: String,

) : WsControllerData, WsThermometersData, WsAdminData {

    override fun getLogText(): String {
        return message
    }
}
