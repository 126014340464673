package admin.ui.devices.connect

import admin.services.auth.AuthService
import admin.services.websocket.WebSocketService
import exntensions.toJson
import kotlinx.browser.window
import logs.debugLog
import models.devices.Device
import models.devices.connection.ConnectionState
import models.devices.controller.event.EventV1
import models.devices.controller.measurements.MeasurementV1
import models.devices.controller.measurements.TemperaturesV1
import models.devices.controller.process.ProcessSettingsV1
import models.devices.controller.settings.auto.AutoSettingsV1
import models.devices.controller.settings.client.ClientSettingsV1
import models.devices.controller.settings.controller.ControllerSettingsV1
import models.devices.controller.settings.stabilization.StabilizationSettingsV1
import navigator.html.StaticHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableElement
import navigator.observable.view.ObservableInput
import navigator.view.LoadResult
import navigator.view.View
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSpanElement
import tools.getElementById
import kotlin.js.Date

class DevicesConnectView(
    authService: AuthService,
    webSocketService: WebSocketService,
) : StaticHtml(constUrlBit = "connect", resourceUrl = "/adminBody/devices/connect/connect.html"), View {

    private val viewModel = DevicesConnectViewModel(
        webSocketService = webSocketService,
        authService = authService,
    )

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")

        viewModel.initializeAsync().await()

        bindElements()

        return LoadResult(isSuccess = true, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        viewModel.disconnect()
        viewModel.destroy()
    }

    private fun bindElements() {
        ObservableElement<HTMLSpanElement>("connect-state")
            .bindObservable(viewModel.connectState) { state, element ->
                element.innerHTML = getStatusName(state ?: ConnectionState.DISCONNECT)
                element.style.color = state?.color ?: ConnectionState.DISCONNECT.color
            }

        ObservableInput<HTMLInputElement>("connect-serial-number")
            .bindObservable(viewModel.connectSerialNumber) { value, element -> element.value = value ?: "" }
            .onInput { value, _ -> viewModel.connectSerialNumber.value = value }

        ObservableButton<HTMLDivElement>("connect-device-button")
            .bindObservable(viewModel.connectState) { state, element ->
                element.innerHTML = if (state == ConnectionState.CONNECT) "Rozłącz" else "Podłącz"
            }
            .onClickCo {
                if (viewModel.connectState.value == ConnectionState.CONNECT) {
                    viewModel.disconnect()
                } else {
                    viewModel.connect()
                }
            }

        ObservableElement<HTMLDivElement>("connect-logs")
            .bindObservable(viewModel.lastControllerLog) { nullableLog, element ->
                val log = nullableLog ?: return@bindObservable
                val time = Date(log.time ?: 0)
                    .let { date ->
                        val hours = date.getHours().toString().let { if (it.length == 1) "&nbsp;$it" else it }
                        val minutes = date.getMinutes().toString().let { if (it.length == 1) "0$it" else it }
                        val seconds = date.getSeconds().toString().let { if (it.length == 1) "0$it" else it }
                        val milliseconds = date.getMilliseconds().toString().let {
                            when (it.length) {
                                1 -> "00$it"
                                2 -> "0$it"
                                else -> it
                            }
                        }

                        "$hours:$minutes:$seconds:$milliseconds"
                    }

                element.innerHTML += "$time ${log.tag} ${log.message} <br>"

                if (viewModel.autoscroll.value == true) {
                    element.scrollTop = element.scrollHeight.toDouble()
                }
            }

        ObservableButton<HTMLButtonElement>("connect-logs-clean")
            .onClickCo {
                getElementById<HTMLDivElement>("connect-logs")?.innerHTML = ""
            }

        ObservableInput<HTMLInputElement>("connect-logs-autoscroll")
            .bindObservable(viewModel.autoscroll) { value, element -> element.checked = value ?: false }
            .onCheckChange { value, _ -> viewModel.autoscroll.value = value }

        ObservableButton<HTMLButtonElement>("connect-process-reset")
            .onClickCo {
                if (window.confirm("Jesteś pewien")) {
                    viewModel.processReset()
                }
            }

        ObservableButton<HTMLButtonElement>("connect-factory-reset")
            .onClickCo {
                if (window.confirm("Jesteś pewien")) {
                    viewModel.factoryReset()
                }
            }

        ObservableElement<HTMLDivElement>("connect-device")
            .bindObservable(viewModel.device) { device, element ->
                element.innerHTML = createDeviceView(device)
            }

        ObservableElement<HTMLDivElement>("connect-process-setting")
            .bindObservable(viewModel.processSettings) { processSettings, element ->
                element.innerHTML = createProcessView(processSettings)
            }

        ObservableElement<HTMLDivElement>("connect-measurement")
            .bindObservable(viewModel.measurementsHtml) { measurementsHtml, element ->
                measurementsHtml?.let { element.innerHTML = it }
            }

        ObservableElement<HTMLDivElement>("connect-controller-setting")
            .bindObservable(viewModel.deviceSettingsHtml) { deviceSettingsHtml, element ->
                deviceSettingsHtml?.let { element.innerHTML = it }
            }

        ObservableElement<HTMLDivElement>("connect-auto-setting")
            .bindObservable(viewModel.autoSettings) { autoSettings, element ->
                element.innerHTML = createAutoSettingsView(autoSettings)
            }

        ObservableElement<HTMLDivElement>("connect-client-setting")
            .bindObservable(viewModel.clientSettingsHtml) { clientSettingsHtml, element ->
                clientSettingsHtml?.let { element.innerHTML = it }
            }

        ObservableElement<HTMLDivElement>("connect-stabilization-setting")
            .bindObservable(viewModel.stabilizationSettingsHtml) { stabilizationSettingsHtml, element ->
                stabilizationSettingsHtml?.let { element.innerHTML = it }
            }

        ObservableElement<HTMLDivElement>("connect-last-event")
            .bindObservable(viewModel.lastEvent) { lastEvent, element ->
                element.innerHTML = createEventView(lastEvent)
            }
    }

    private fun createDeviceView(device: Device?): String {
        return """
          Controller:
          <table border="1">
            <tr>
              <th>${Device::serialNumber.name}</th>
              <th>${Device::password.name}</th>
              <th>${Device::model.name}</th>
              <th>${Device::softwareVersion.name}</th>
              <th>${Device::hardwareVersion.name}</th>
            </tr>
            <tr>
              <td>${device?.serialNumber}</td>
              <td>${device?.password}</td>
              <td>${device?.model}</td>
              <td>${device?.softwareVersion}</td>
              <td>${device?.hardwareVersion}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createProcessView(processSettings: ProcessSettingsV1?): String {
        return """
          ProcessSettings:
          <table border="1">
            <tr>
              <th>power</th>
              <th>mode</th>
              <th>valve1</th>
              <th>valve2</th>
              <th>valve3</th>
              <th>valve4</th>
              <th>valve5</th>
              <th>valve6</th>
              <th>electricValve1</th>
              <th>electricValve2</th>
              <th>precisionValve1</th>
              <th>precisionValve2</th>
              <th>precisionValve3</th>
            </tr>
            <tr>
                <td>${processSettings?.power}</td>
                <td>${processSettings?.mode}</td>
                <td>${processSettings?.valve1}</td>
                <td>${processSettings?.valve2}</td>
                <td>${processSettings?.valve3}</td>
                <td>${processSettings?.valve4}</td>
                <td>${processSettings?.valve5}</td>
                <td>${processSettings?.valve6}</td>
                <td>${processSettings?.electricValve1}</td>
                <td>${processSettings?.electricValve2}</td>
                <td>v:${processSettings?.precisionValve1?.value}, s:${processSettings?.precisionValve1?.state}</td>
                <td>v:${processSettings?.precisionValve2?.value}, s:${processSettings?.precisionValve2?.state}</td>
                <td>v:${processSettings?.precisionValve3?.value}, s:${processSettings?.precisionValve3?.state}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createAutoSettingsView(autoSettings: AutoSettingsV1?): String {
        return """
          AutoSettings:
          <table border="1">
            <tr>
              <th>Name</th>
            </tr>
            <tr>
              <td>${autoSettings?.name}</td>
            </tr>
          </table>
          <br>
        """.trimIndent()
    }

    private fun createEventView(lastEvent: EventV1?): String {
        return """
          Last Event:
          ${lastEvent?.data?.toJson()}
        """.trimIndent()
    }

    private fun getStatusName(state: ConnectionState): String = when (state) {
        ConnectionState.CONNECT -> "Połączony"
        ConnectionState.DISCONNECT -> "Rozłączony"
        ConnectionState.REFRESHING -> "Odświeżanie"
        else -> "Rozłączony"
    }

    companion object {
        const val TAG = "[CONNECT]"
    }
}
