package admin.ui.users

import navigator.observable.vm.ObservableValue
import navigator.observable.vm.ViewModel

class UsersViewModel : ViewModel() {

    val currentView = ObservableValue<String>()

    fun initialize(view: String) {
        currentView.value = view
    }

    fun reloadView(view: String) {
        currentView.value = view
    }
}
