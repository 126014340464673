package models.devices.connection

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE
import rules.ModelDestination.THERMOMETERS
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsControllerData
import websocket.WsDataCodes.OPEN_CONNECTION_V1
import websocket.WsThermometersData

@Model(CORE, CLIENT, ADMIN, CONTROLLER, THERMOMETERS)
@Serializable
@SerialName(OPEN_CONNECTION_V1)
data class OpenConnectionV1(

    @SerialName("a")
    val accessCode: String,

) : WsControllerData, WsThermometersData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return "OpenConnectionV1(accessCode=$accessCode)"
    }
}
