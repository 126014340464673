package admin.ui

import admin.services.Services
import admin.ui.dashboard.DashboardView
import admin.ui.devices.DevicesView
import admin.ui.login.LoginView
import admin.ui.users.UsersView
import exntensions.boldFont
import exntensions.gone
import exntensions.normalFont
import exntensions.show
import kotlinx.browser.document
import logs.debugLog
import logs.infoLog
import navigator.Navigator
import navigator.html.StaticHtml
import navigator.observable.view.ObservableButton
import navigator.view.LoadResult
import navigator.view.View
import navigator.view.ViewNode
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLUListElement
import tools.co

class AdminView(
    override val navigator: Navigator,
    private val services: Services,
) : ViewNode {
    override val urlBitIndex: Int = 0
    override val contentViewId: String = "content"
    override var htmlView: StaticHtml? = null

    private val viewModel = AdminViewModel(
        authService = services.auth,
        webSocketService = services.webSocket,
        browserService = services.browser,
    )

    override suspend fun onLoad(addressUrl: List<String>): LoadResult {
        debugLog(TAG, "Loading view...")

        bindElements()

        viewModel.isUserLogged.onChanged { isLogged ->
            co {
                if (isLogged == true) {
                    viewModel.currentView.value = "dashboard"
                    navigator.navigate(listOf("dashboard"))
                } else {
                    viewModel.currentView.value = "login"
                    navigator.navigate(listOf("login"))
                }
            }
        }

        val isLogged = viewModel.initializeAsync().await()

        return LoadResult(isSuccess = isLogged, null)
    }

    override suspend fun onReload(addressUrl: List<String>) {
        debugLog(TAG, "Reloading view...")
    }

    override suspend fun onDestroy() {
        debugLog(TAG, "Destroying view...")
        (htmlView as? View)?.onDestroy()
    }

    override suspend fun navigateTo(addressUrl: List<String>) {
        val urlBit = addressUrl[urlBitIndex]
        debugLog(TAG, "navigate($urlBit)")
        run(addressUrl, urlBit)
    }

    override suspend fun buildView(addressUrl: List<String>, urlBit: String) {
        debugLog(TAG, "View building: $urlBit")

        htmlView = when (urlBit) {
            "login" -> LoginView(services.auth, services.cookie)
            "dashboard" -> DashboardView(services.auth, services.browser, services.api.system, services.webSocket)
            "devices" -> DevicesView(navigator, services)
            "users" -> UsersView(navigator, services)
            else -> throw Exception("Invalid path for $urlBit at layer 0.")
        }

        document.getElementById(contentViewId)
            ?.unsafeCast<HTMLElement>()
            ?.innerHTML = htmlView?.getView()!!

        navigator.updateUrlHistory(addressUrl, replace = true)
    }

    private fun bindElements() {
        ObservableButton<HTMLUListElement>("dashboard-button")
            .bindObservable(viewModel.isUserLogged) { value, element ->
                if (value == true) element.show() else element.gone()
            }
            .bindObservable(viewModel.currentView) { value, element ->
                if (value == "dashboard") element.boldFont() else element.normalFont()
            }
            .onClickCo {
                viewModel.currentView.value = "dashboard"
                navigator.navigate(listOf("dashboard"))
            }

        ObservableButton<HTMLUListElement>("users-button")
            .bindObservable(viewModel.isUserLogged) { value, element ->
                if (value == true) element.show() else element.gone()
            }
            .bindObservable(viewModel.currentView) { value, element ->
                if (value == "users") element.boldFont() else element.normalFont()
            }
            .onClickCo {
                viewModel.currentView.value = "users"
                navigator.navigate(listOf("users", "manager"))
            }

        ObservableButton<HTMLUListElement>("devices-button")
            .bindObservable(viewModel.isUserLogged) { value, element ->
                if (value == true) element.show() else element.gone()
            }
            .bindObservable(viewModel.currentView) { value, element ->
                if (value == "devices") element.boldFont() else element.normalFont()
            }
            .onClickCo {
                viewModel.currentView.value = "devices"
                navigator.navigate(listOf("devices", "manager"))
            }

        ObservableButton<HTMLUListElement>("logout-button")
            .bindObservable(viewModel.isUserLogged) { value, element ->
                if (value == true) element.show() else element.gone()
            }
            .onClick {
                infoLog(TAG, "Logout")
                viewModel.logout()
            }
    }

    companion object {
        const val TAG = "[ADMIN]"
    }
}
