package admin.models.websocket

import rules.Model
import rules.ModelDestination.ADMIN

@Model(ADMIN)
enum class WsAdminAction {
    OPEN_CONNECTION,
    CLOSE_CONNECTION,
    PROCESS_SETTINGS,
    DEVICE_SETTINGS,
    THERMOMETERS_SETTINGS,
    CLIENT_SETTINGS,
    MEASUREMENTS,
    FORCE_DEVICE_PING,
    DEVICE_DISCONNECTED,
    FETCH_DEVICE_DATA,
    AUTO_SETTINGS,
    EVENT,
    STABILIZATION_SETTINGS,
    DEVICE_LOG,
    CONNECT_DEVICE,
    DISCONNECT_DEVICE,
    FACTORY_RESET,
    PROCESS_RESET,
    CONNECT_SERVER_STATE,
    DISCONNECT_SERVER_STATE,
    SERVER_STATE_CPU,
    SERVER_STATE_MEMORY,
    SERVER_STATE_NETWORK,
    DEVICE_INFO,
    UNKNOWN,
}
