package admin.ui.devices.updates.windows.add

import admin.services.updates.UpdatesService
import kotlinx.browser.window
import kotlinx.coroutines.await
import logs.infoLog
import navigator.html.DynamicHtml
import navigator.observable.view.ObservableButton
import navigator.observable.view.ObservableElement
import navigator.observable.view.ObservableInput
import navigator.observable.view.ObservableTextArea
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLParagraphElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.files.FileList
import org.w3c.files.get
import tools.co
import tools.getElementById

class DeviceUpdateAddWindowView(
    private val initVersion: String,
    private val device: String,
    private val model: String,
    val updatesService: UpdatesService,
    val onSaveClicked: () -> Unit,
    val onCloseClicked: () -> Unit,
) : DynamicHtml() {

    private lateinit var view: String
    private lateinit var viewModel: DeviceUpdateAddWindowViewModel

    suspend fun createView(): String {
        view = window
            .fetch("${window.location.origin}$WINDOW_HTML_PATH")
            .then { it.text() }.await().toString()

        viewModel = DeviceUpdateAddWindowViewModel(initVersion, device, model, updatesService)

        return view
    }

    fun load() {
        ObservableInput<HTMLInputElement>("version-name")
            .bindObservable(viewModel.version) { value, element -> element.value = value ?: "" }
            .onInput { value, _ ->
                viewModel.version.value = value
            }

        ObservableTextArea<HTMLTextAreaElement>("changelog-name")
            .bindObservable(viewModel.changelog) { value, element -> element.value = value ?: "" }
            .onInput { value, _ ->
                viewModel.changelog.value = value
            }

        ObservableElement<HTMLDivElement>("drop-area")
            .onInitialize {
                it.ondragover = { event ->
                    event.preventDefault()
                    it.classList.add("active")
                }

                it.ondragleave = { event ->
                    it.classList.remove("active")
                }

                it.ondrop = { event ->
                    event.preventDefault()
                    uploadFile(event.dataTransfer?.files)
                }
            }

        ObservableInput<HTMLInputElement>("upload-file-name")
            .onChange { _, htmlInputElement -> uploadFile(htmlInputElement.files) }

        ObservableButton<HTMLInputElement>("upload-button-name")
            .onClick { getElementById<HTMLInputElement>("upload-file-name")?.click() }

        ObservableElement<HTMLParagraphElement>("file-info")
            .bindObservable(viewModel.updateFile) { update, element ->
                element.innerHTML = if (update != null) {
                    "Załadowano: ${update.name}"
                } else {
                    "Przeciągnij tutaj plik aktualizacji"
                }
            }

        ObservableButton<HTMLDivElement>("save-new-update-button")
            .onClick {
                infoLog(TAG, "Clicked Save")

                co {
                    if (viewModel.save()) {
                        onSaveClicked()
                    }
                }
            }

        ObservableButton<HTMLDivElement>("cancel-new-update-button")
            .onClick {
                infoLog(TAG, "Clicked Cancel")
                onCloseClicked()
            }

        ObservableElement<HTMLDivElement>("window-error")
            .bindObservable(viewModel.errorMessage) { errorMessage, element -> element.innerHTML = errorMessage ?: "" }
    }

    private fun uploadFile(fileList: FileList?) {
        try {
            val file = fileList?.get(0) ?: throw NullPointerException()

            if (!file.name.endsWith(".bin")) throw Exception("Wrong file type: ${file.name}")

            viewModel.updateFile.value = file
            viewModel.errorMessage.value = ""
        } catch (e: Exception) {
            viewModel.errorMessage.value = e.message
            console.error("File upload error: $e")
        }
    }

    fun destroy() {
        viewModel.destroy()
    }

    companion object {
        private const val TAG = "[NEW_UPDATE_WINDOW]"
        private const val WINDOW_HTML_PATH = "/adminBody/devices/updates/updates_add_window.html"
    }
}
