package models.access

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import models.devices.DeviceType
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE

@Model(CORE, CLIENT, ADMIN)
@Serializable
data class AccessDevice(

    @SerialName("s")
    val serialNumber: String,

    @SerialName("m")
    val model: String,

    @SerialName("t")
    val type: DeviceType,

    @SerialName("sv")
    val softwareVersion: String,

    @SerialName("hv")
    val hardwareVersion: String,
)
