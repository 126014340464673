package models.devices.controller.measurements

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CONTROLLER
import rules.ModelDestination.CORE
import websocket.WsAdminData
import websocket.WsClientData
import websocket.WsControllerData
import websocket.WsDataCodes.CONTROLLER_MEASUREMENTS_V1

@Model(CORE, CLIENT, ADMIN, CONTROLLER)
@Serializable
@SerialName(CONTROLLER_MEASUREMENTS_V1)
data class MeasurementV1(

    @SerialName("t")
    val temperatures: TemperaturesV1,

    @SerialName("ifd")
    val isFloodDetected: Boolean,

    @SerialName("w")
    val watt: Float,

) : WsControllerData, WsAdminData, WsClientData {

    override fun getLogText(): String {
        return "MeasurementV1([t1=${temperatures.temperature1}, t2=${temperatures.temperature2}, t3=${temperatures.temperature3}, t4=${temperatures.temperature4}, t5=${temperatures.temperature5}, isFloodDetected=$isFloodDetected, watt=$watt)"
    }
}
