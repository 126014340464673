package models.devices

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import models.access.AccessDevice
import rules.Model
import rules.ModelDestination.ADMIN
import rules.ModelDestination.CLIENT
import rules.ModelDestination.CORE
import websocket.WsAdminData
import websocket.WsDataCodes.DEVICE

@Model(CORE, CLIENT, ADMIN)
@Serializable
@SerialName(DEVICE)
data class Device(

    @SerialName("s")
    val serialNumber: String,

    @SerialName("p")
    val password: String,

    @SerialName("m")
    val model: String,

    @SerialName("t")
    val type: DeviceType,

    @SerialName("sv")
    val softwareVersion: String,

    @SerialName("hv")
    val hardwareVersion: String,
) : WsAdminData {

    fun toAccessDevice(): AccessDevice {
        return AccessDevice(
            serialNumber = serialNumber,
            model = model,
            type = type,
            softwareVersion = softwareVersion,
            hardwareVersion = hardwareVersion,
        )
    }
}
